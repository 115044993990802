import './ez-heading.scss';

const prefix = 'ez-heading';

export function EzHeading({ title, contentEnd, hideBorder }: any) {
  const renderTitle = () => <div className={`${prefix}__title`}>{title}</div>;
  const renderContentEnd = () => <div className={`${prefix}__content-end`}>{contentEnd}</div>;

  return (
    <div className={prefix}>
      <div className={`${prefix}__head`}>
        {renderTitle()}
        {renderContentEnd()}
      </div>
      {!hideBorder ? <div className="divider mt-2"></div> : null}
    </div>
  );
}
