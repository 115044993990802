import { createTheme } from '@mui/material';

declare module '@mui/material/styles' {
	interface Palette {
		tprimary: Palette['primary'];
	}

	interface PaletteOptions {
		tprimary?: PaletteOptions['primary'];
	}
}

declare module '@mui/material/Button' {
	interface ButtonPropsColorOverrides {
		tprimary: true;
	}
}

declare module '@mui/material/IconButton' {
	interface IconButtonPropsColorOverrides {
		tprimary: true;
	}
}

export const EZ_THEME = createTheme({
	palette: {
		primary: {
			main: '#3066BE'
		},
		secondary: {
			main: '#6E6E6E'
		},
		tprimary: {
			main: '#212121'
		},
		warning: {
			main: '#F9A825'
		},
		error: {
			main: '#F74A4A'
		},
		success: {
			main: '#2E7D32'
		}
	},
	components: {
		MuiMenu: {
			styleOverrides: {
				paper: { borderRadius: 8 }
			}
		},
		MuiButton: {
			styleOverrides: {
				root: { borderRadius: '0.5rem' }
			}
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					borderRadius: '0.75rem'
				}
			}
		},
		MuiTableContainer: {
			styleOverrides: {
				root: {
					border: 'solid 1px #D2D2D2',
					borderTopLeftRadius: '1rem',
					borderTopRightRadius: '1rem',
					borderBottom: '0'
				}
			}
		},
		MuiTableRow: {
			styleOverrides: {
				head: {
					borderBottom: 'solid 3px #D2D2D2'
				},
				root: {
					borderColor: '#D2D2D2'
				}
			}
		},
		MuiTableCell: {
			styleOverrides: {
				head: {
					fontWeight: 'bold'
				}
			}
		},
		MuiDialogActions: {
			styleOverrides: {
				root: {
					paddingLeft: '1.25rem',
					paddingRight: '1.5rem',
					paddingBottom: '1.25rem'
				}
			}
		}
	}
});
