import './clients-stats.scss';
import {t} from 'i18next';
import {Fragment} from 'react';
import {useTranslation} from 'react-i18next';
import {EzCard} from '../../../components/core/ez-card/EzCard';
import {EzTable} from '../../../components/core/ez-table/EzTable';
import moment from 'moment';
import {ClientsOrderPerMonth} from './clients-orders-per-month/ClientsOrdersPerMonth';

export function ClientsStats() {
  const prefix = 'clients-stats';
  const {t} = useTranslation();

  return (
    <div className={prefix}>
      <ClientsOrderPerMonth />
    </div>
  );
}
