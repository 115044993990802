import { ClientsState } from '../../om/features/clients.state';
import { createAction, deleteAction, getAllAction } from './clients.actions';

export const getAllBuilders = (builder: any): any =>
	builder
		.addCase(getAllAction.pending, (state: ClientsState) => {
			state.isClientsLoading = true;
		})
		.addCase(getAllAction.rejected, (state: ClientsState, action: any) => {
			state.isClientsLoading = false;
		})
		.addCase(getAllAction.fulfilled, (state: ClientsState, action: any) => {
			state.isClientsLoading = false;
			state.clients = action.payload;
		});

export const createClientBuilders = (builder: any): any =>
	builder
		.addCase(createAction.pending, (state: ClientsState) => {
			state.isAddClientSuccess = false;
		})
		.addCase(createAction.rejected, (state: ClientsState, action: any) => {
			state.isAddClientSuccess = false;
		})
		.addCase(createAction.fulfilled, (state: ClientsState, action: any) => {
			state.isAddClientSuccess = true;
		});

export const deleteClientBuilders = (builder: any): any =>
	builder
		.addCase(deleteAction.pending, (state: ClientsState) => {
			state.isDeleteClientSuccess = false;
		})
		.addCase(deleteAction.rejected, (state: ClientsState, action: any) => {
			state.isDeleteClientSuccess = false;
		})
		.addCase(deleteAction.fulfilled, (state: ClientsState, action: any) => {
			state.isDeleteClientSuccess = true;
		});
