import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { IoWarningOutline } from 'react-icons/io5';

export function Settings() {
  const { t } = useTranslation();

  return (
    <div className="container mx-auto px-6 xl:px-8 mb-5">
      <div className="text-3xl breadcrumbs font-bold">
        <h1>{t('PAGES.SETTINGS_TITLE')}</h1>
      </div>
      <div className="alert alert-info shadow-lg mt-5">
        <div>
          <IoWarningOutline />
          <span>This page is under development.</span>
        </div>
      </div>
    </div>
  );
}
