import { NavLink } from 'react-router-dom';
import { MenuItem } from '../../../om/menu-item';
import './ezmenu.scss';

const prefix = 'ez-menu';

export interface EzMenuInterface {
	menu: Array<MenuItem>;
}

const getMenuItemClass = (navItemPrefix: string, isActive: boolean) => {
	const menuItemClasses = [ navItemPrefix ];
	if (isActive) menuItemClasses.push(`${navItemPrefix}--active`);
	return menuItemClasses.join(' ');
};

const renderSidenavItem = (item: MenuItem, key: number) => (
	<li key={key}>
		<NavLink className={({ isActive, isPending }: any) => getMenuItemClass(`${prefix}__item`, isActive)} to={item.path} data-tip={item.title}>
			<div className={`${prefix}__item-icon`}>{item.icon}</div>
			<div className={`font-medium ${prefix}__item-title`}>{item.title}</div>
		</NavLink>
	</li>
);

export const EzMenu = ({ menu }: EzMenuInterface) => {
	return <ul className={`${prefix}__menu overflow-hidden menu w-auto p-1 gap-1`}>{menu.map((item, key) => renderSidenavItem(item, key))}</ul>;
};
