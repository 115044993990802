import { Button, IconButton, InputAdornment, OutlinedInput, Popover, Typography } from '@mui/material';
import './ez-toolbar.scss';
import { Filter, FilterList, Search } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { inputUtil } from '../../../utils/input.util';

const prefix = 'ez-toolbar';

export interface EzToolbarInterface {
  onSearch: (searchString?: string) => void;
  onFilterButtonClick?: (e: any) => void;
  filterContent?: any;
  contentEnd?: any;
  filterChips?: any;
}

export interface EzToolbarFormInterface {}

export function EzToolbar({ onSearch, onFilterButtonClick, filterContent, contentEnd, filterChips }: EzToolbarInterface) {
  const initialToolbarForm = {
    search: ''
  };
  const [toolbarForm, setToolbarForm] = useState<any>(initialToolbarForm);
  const [popoverAnchorElem, setPopoverAnchorElem] = useState<HTMLButtonElement | null>(null);
  const { getUpdatedValue } = inputUtil();

  const filterPanelIsOpen = Boolean(popoverAnchorElem);
  const filterPanelId = filterPanelIsOpen ? 'simple-popover' : undefined;

  const searchCallback = () => {
    onSearch(toolbarForm.search);
  };

  const onSearchbarChange = (e: any) => {
    setToolbarForm((prevState: any) => getUpdatedValue(e, prevState));
  };

  useEffect(() => {
    searchCallback();
  }, [toolbarForm]);

  const renderSearchbar = () => (
    <div className={`${prefix}__searchbar`}>
      <form onSubmit={(e) => e.preventDefault()}>
        <OutlinedInput
          value={toolbarForm.search}
          name="search"
          onChange={onSearchbarChange}
          placeholder="Rag. sociale / Codice"
          className="w-full max-w-xs"
          size="small"
          endAdornment={
            <InputAdornment position="end">
              <IconButton type="submit">
                <Search />
              </IconButton>
            </InputAdornment>
          }
        />
      </form>
    </div>
  );

  const renderContentStart = () => (
    <div className={`${prefix}__content-start`}>
      {renderSearchbar()} {renderFilters()}
    </div>
  );

  const renderFilters = () => {
    if (filterContent)
      return (
        <div className={`${prefix}__filters`}>
          <Button id={filterPanelId} onClick={onFilterButtonClick} color="secondary" className="h-10" startIcon={<FilterList />}>
            Filtri
          </Button>
          {filterContent}
        </div>
      );
  };

  const renderContentEnd = () => <div className={`${prefix}__content-end`}>{contentEnd}</div>;

  return (
    <div className={prefix}>
      <div className={`${prefix}__toolbar`}>
        {renderContentStart()} {renderContentEnd()}
      </div>
      {filterChips}
    </div>
  );
}
