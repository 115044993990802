import { useTranslation } from 'react-i18next';
import './stats.scss';
import { EzNavGroup } from '../../components/core/ez-nav-group/EzNavGroup';
import { ClientsStats } from './clients-stats/ClientsStats';
import { EzCard } from '../../components/core/ez-card/EzCard';
import { EzHeading } from '../../components/core/ez-heading/EzHeading';
import { TargetStats } from './targets-stats/TargetStats';

export function Stats() {
	const { t } = useTranslation();
	const navItems = [ 'clients' ];
	return (
		<div className="stats__container">
			<EzCard>
				<EzHeading title={t('STATS.PAGE_TITLE')} hideBorder />
				<EzNavGroup navItems={navItems} translatePrefix="STATS.NAV">
					<div className="stats__content" id="clients">
						<ClientsStats />
					</div>
				</EzNavGroup>
			</EzCard>
		</div>
	);
}
