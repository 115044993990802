import { getProfileAction, loginAction } from './auth.actions';

export const loginBuilders = (builder: any): any => {
	builder
		.addCase(loginAction.pending, (state: any) => {
			state.isLoading = true;
			state.isSuccess = false;
			state.isError = false;
		})
		.addCase(loginAction.rejected, (state: any, action: any) => {
			state.isLoading = false;
			state.isError = true;
			state.isSuccess = false;
			state.user = null;
		})
		.addCase(loginAction.fulfilled, (state: any, action: any) => {
			state.isLoading = false;
			state.isSuccess = true;
			state.user = action.payload.user;
		});
	return builder;
};

export const logoutBuilders = (builder: any): any => {
	builder
		.addCase(loginAction.pending, (state: any) => {
			state.isLoading = true;
			state.isLogoutSuccess = false;
			state.isLogoutError = false;
		})
		.addCase(loginAction.rejected, (state: any, action: any) => {
			state.isLoading = false;
			state.isLogoutError = true;
			state.isLogoutSuccess = false;
			state.user = null;
		})
		.addCase(loginAction.fulfilled, (state: any, action: any) => {
			state.isLoading = false;
			state.isLogoutSuccess = true;
			state.user = null;
		});
	return builder;
};

export const getProfileBuilders = (builder: any): any => {
	builder
		.addCase(getProfileAction.pending, (state: any) => {
			state.isSuccess = false;
			state.isLoading = true;
			state.isError = false;
		})
		.addCase(getProfileAction.rejected, (state: any, action: any) => {
			state.user = null;
			state.isLoading = false;
			state.isSuccess = false;
			state.isError = true;
		})
		.addCase(getProfileAction.fulfilled, (state: any, action: any) => {
			state.user = {
				...action.payload,
				...action.payload.user
			};
			delete state.user.user;
			state.isLoading = false;
			state.isSuccess = true;
			state.isError = false;
		});
	return builder;
};
