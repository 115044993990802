import { t } from 'i18next';
import { EzModal } from '../../../components/core/ez-modal/EzModal';
import { useAppDispatch } from '../../../hooks/redux-toolkit.hook';
import { deleteAction } from '../../../features/clients/clients.actions';
import { useToast } from '../../../hooks/toaster.hook';
import { Button } from '@mui/material';

export const DeleteClientModal = ({ modalId, callback, closeCallback, clientToDelete, isOpen }: any) => {
  const toaster = useToast();
  const dispatch = useAppDispatch();
  const dispatchDeleteClient = async (e: any) => {
    e.preventDefault();
    const result = await dispatch(deleteAction(clientToDelete?.id));
    if (result.error) toaster.error(result.error?.error);
    else {
      callback();
      toaster.success(`Cliente ${clientToDelete.id} - ${clientToDelete.company} eliminato con successo`);
      closeCallback();
    }
  };

  const deleteClientModalActions = () => (
    <Button variant='contained' onClick={dispatchDeleteClient}>
      {t('COMMON.CONFIRM')}
    </Button>
  );

  return (
    <EzModal isOpen={isOpen} id={modalId} actions={deleteClientModalActions()} closeCallback={closeCallback}>
      <p className="py-4">
        Sei sicuro di voler eliminare{' '}
        <b>
          {clientToDelete?.code} - {clientToDelete?.company}
        </b>
      </p>
    </EzModal>
  );
};
