import logo100 from '../../assets/images/logo.png';
import {
	AnchorHTMLAttributes,
	Fragment,
	MouseEventHandler,
	useRef,
	useState
} from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { logoutAction } from '../../features/auth/auth.actions';
import { useOutsideClickHandler } from '../../hooks/outside-click-handler.hook';
import { useAppDispatch, useAppSelector } from '../../hooks/redux-toolkit.hook';
import { MenuItem } from '../../om/menu-item';
import { EzMenu } from '../core/ez-menu/EzMenu';
import './sidenav.scss';

const prefix = 'ew-sidenav';
const SETTINGS_PATH: string = '/settings';

export interface SideNavInterface {
	children?: any;
	menu: Array<MenuItem>;
}

export const SideNav = ({ children, menu }: SideNavInterface) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { user } = useAppSelector((state: any) => state.auth);
	const [isMenuExpanded, setIsExpanded] = useState(true);
	const [isUserMenuExpanded, setIsUserMenuExpanded] = useState(false);

	const userMenuRefWrapper = useRef(null);

	const openUserMenu = () => {
		setIsUserMenuExpanded(!isUserMenuExpanded);
	};

	const closeFloatingBox = () => {
		setIsUserMenuExpanded(false);
	};

	const logoutCallback = async (e: any) => {
		e.preventDefault();
		await dispatch(logoutAction());
		navigate('/');
	};

	const goToDashboard = () => {
		navigate('/dashboard');
	};

	useOutsideClickHandler(userMenuRefWrapper, closeFloatingBox);

	const getAvatarContent = (): string =>
		user?.name || user?.surname
			? `${user.name[0]}${user.surname[0]}`.toUpperCase()
			: user.username[0].toUpperCase();

	const getProfileLabel = (): string =>
		user?.name ? user.name : user.username;

	return (
		<Fragment>
			<div
				className={`${prefix}-container transition-[width] shadow-md duration-300 ${
					isMenuExpanded ? 'w-60' : 'w-[4.5rem]'
				}`}
			>
				<div
					className={`${prefix} bg-base-100 text-content transition-[width] px-4 duration-300 ${
						isMenuExpanded ? 'w-56' : 'w-[4.5rem]'
					}`}
				>
					<div className={`${prefix}__main`}>
						<div
							className={`${prefix}__header navbar text-primary border-b-2 mb-4 pb-4`}
						>
							<a
								onClick={goToDashboard}
								className='btn btn-ghost normal-case text-2xl justify-start items-center gap-2 w-full'
							>
								<img src={logo100} alt='logo di EzWork' width={35} />
								EzWork
							</a>
						</div>
						<EzMenu menu={menu} />
					</div>
					<ul
						ref={userMenuRefWrapper}
						className={`${prefix}__footer menu dropdown dropdown-top w-auto p-1 gap-1 border-t-2 `}
					>
						<li>
							<div className={`${prefix}__avatar`} onClick={openUserMenu}>
								<div className={`rounded-full border-2 ${prefix}__avatar-img`}>
									<span className='text-base-100'>{getAvatarContent()}</span>
								</div>
								<div className={`${prefix}__username`}>{getProfileLabel()}</div>
							</div>
						</li>
						{isUserMenuExpanded && (
							<ul className='absolute bottom-20 mt-3 p-2 shadow menu menu-compact bg-base-100 rounded-box w-52'>
								<li>
									<NavLink to={SETTINGS_PATH}>Impostazioni</NavLink>
								</li>
								<li>
									<a onClick={logoutCallback}>Esci</a>
								</li>
							</ul>
						)}
					</ul>
				</div>
			</div>
			{children}
		</Fragment>
	);
};
