import { Add } from '@mui/icons-material';
import { Box, Button, LinearProgress } from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaPen, FaTrash } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { EzCard } from '../../components/core/ez-card/EzCard';
import { EzHeading } from '../../components/core/ez-heading/EzHeading';
import { EzTable } from '../../components/core/ez-table/EzTable';
import {
  getAllTargetsAction,
  getOneTargetAction,
} from '../../features/targets/targets.actions';
import { useModal } from '../../hooks';
import { useAppSelector } from '../../hooks/redux-toolkit.hook';
import { TargetDTO } from '../../om/dto/targets/target.dto';
import { EzTableColumnType } from '../../om/enum/ez-table-column-type.enum';
import { TargetKind } from '../../om/enum/target-kind.enum';
import { EzTableAction } from '../../om/ez-table-action';
import { EzTableColumn } from '../../om/ez-table-column';
import useCurrency from '../../utils/currency.util';
import { DeleteTargetModal } from './delete-target-modal/DeleteTargetModal';
import { TargetFormModal } from './target-form-modal/TargetFormModal';
import './targets.scss';

export function Targets() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { targets, isTargetDetailsLoading } = useAppSelector(
    (state: any) => state.targets
  );

  const [targetIdToEdit, setTargetIdToEdit] = useState(0);
  const [targetToDelete, setTargetToDelete] = useState<TargetDTO | null>(null);
  const [targetToEdit, setTargetToEdit] = useState<any>(null);
  const { formatCurrency } = useCurrency();

  const [isFormModalOpen, openFormModal, closeFormModal] = useModal();
  const [isDeleteModalOpen, openDeleteModal, closeDeleteModal] = useModal();

  useEffect(() => {
    dispatch(getAllTargetsAction());
  }, []);

  const getActualGoalStatusFromTarget = (item: any): string => {
    if (item.kind == TargetKind.AMOUNT_ORDERS)
      return formatCurrency(item.amountOrders);
    return item.numOrders;
  };

  const renderTargetCell = (item: TargetDTO) => (
    <div className="targets__target-cell">
      <div className="targets__target-value">
        {item.kind == TargetKind.NUM_ORDERS
          ? item.target
          : formatCurrency(item.target)}
      </div>
      <div>
        {item.kind == TargetKind.NUM_ORDERS
          ? 'Totale ordini'
          : 'Totale importo ordini'}
      </div>
    </div>
  );

  const getGoalPercentageFromTarget = (item: any): number => {
    if (item.kind == TargetKind.AMOUNT_ORDERS)
      return (item.amountOrders / item.target) * 100;
    return (item.numOrders / item.target) * 100;
  };

  
  const getProgressColor = (
    percentage: number
  ): 'error' | 'warning' | 'success' => {
    if (percentage <= 40) return 'error';
    if (percentage <= 99) return 'warning';
    return 'success';
  };
  
  const getProgressValue = (value: number):number => value >= 100 ? 100 : value;
  
  const renderProgressCell = (item: any) => (
    <Box sx={{ width: '100%' }}>
      <LinearProgress
        sx={{
          height: '12px',
          borderRadius: 5,
          background: '#FAFAFA',
          border: 'solid 1px #D2D2D2',
        }}
        color={getProgressColor(getGoalPercentageFromTarget(item))}
        variant="determinate"
        value={getProgressValue(getGoalPercentageFromTarget(item))}
      />
    </Box>
  );

  const renderTargetCounters = (item: any) => (
    <div className="flex flex-col">
      <div className="flex flex-row justify-between">
        <div className="font-semibold">
          {getActualGoalStatusFromTarget(item)}
        </div>
        <div className="font-semibold">
          {getGoalPercentageFromTarget(item).toFixed(2)}%
        </div>
      </div>
      {renderProgressCell(item)}
    </div>
  );

  const tableColumnSettings: Array<EzTableColumn> = [
    {
      id: 'startDate',
      label: 'Data inizio',
      columnType: EzTableColumnType.DATE,
      dateFormat: 'DD/MM/YYYY',
    },
    {
      id: 'endDate',
      label: 'Data Fine',
      columnType: EzTableColumnType.DATE,
      dateFormat: 'DD/MM/YYYY',
    },
    {
      id: 'description',
      label: 'Descrizione',
      columnType: EzTableColumnType.TEXT,
    },
    {
      id: 'target',
      label: 'Obbiettivo',
      columnType: EzTableColumnType.CUSTOM,
      customColumn: renderTargetCell,
      order: 'center',
    },
    {
      id: 'counters',
      label: 'Realizzo',
      columnType: EzTableColumnType.CUSTOM,
      customColumn: renderTargetCounters,
      order: 'center',
    },
  ];

  const tableActions: Array<EzTableAction> = [
    {
      desription: t('COMMON.EDIT'),
      icon: <FaPen />,
      className: 'btn-primary',
      onClick: (item: TargetDTO) => {
        setTargetIdToEdit(item.id);
        openFormModal();
      },
    },
    {
      desription: t('COMMON.DELETE'),
      icon: <FaTrash />,
      className: 'btn',
      onClick: (item: TargetDTO) => {
        setTargetToDelete(item);
        openDeleteModal();
      },
    },
  ];

  const resetFormModal = () => {
    closeFormModal();
    setTargetIdToEdit(0);
  };

  const getTargetToEditDetails = async () => {
    const response = await dispatch(getOneTargetAction(targetIdToEdit));
    const targetToEditDetails: TargetDTO = response.payload;
    setTargetToEdit(targetToEditDetails);
  };

  const checkTargetToEdit = () => {
    if (targetIdToEdit) {
      getTargetToEditDetails();
    } else setTargetToEdit(null);
  };

  useEffect(() => {
    checkTargetToEdit();
  }, [targetIdToEdit]);

  const resetDeleteModal = () => {
    closeDeleteModal();
    setTargetToDelete(null);
  };

  const onDeleteTarget = async () => {
    resetDeleteModal();
    await dispatch(getAllTargetsAction());
  };

  return (
    <Fragment>
      <div className="targets__container mt-5">
        <EzCard>
          <EzHeading
            title={t('TARGETS.PAGE_TITLE')}
            contentEnd={
              <Button
                onClick={openFormModal}
                startIcon={<Add />}
                variant="contained"
                color="primary"
                type="submit"
                size="large"
              >
                {t('TARGETS.NEW_LABEL')}
              </Button>
            }
          />
          <EzTable
            id="targets-list"
            columns={tableColumnSettings}
            data={targets}
            actions={tableActions}
          />
        </EzCard>
      </div>
      <TargetFormModal
        modalId="target-form-modal"
        isOpen={isFormModalOpen}
        itemToEdit={targetToEdit}
        isLoading={isTargetDetailsLoading}
        closeModalCallback={resetFormModal}
      />
      <DeleteTargetModal
        modalId="delete-target-modal"
        isOpen={isDeleteModalOpen}
        targetToDelete={targetToDelete}
        callback={onDeleteTarget}
        closeModalCallback={resetDeleteModal}
      />
    </Fragment>
  );
}
