import { PropsWithChildren, useEffect } from 'react';
import { useState, Children, isValidElement } from 'react';
import { EzInput } from '../ez-input/EzInput';
import { EzInputValidator } from '../../../om/enum/ez-input-validator.enum';
import { useToast } from '../../../hooks/toaster.hook';
import { EzInputAutocomplete } from '../ez-input-ahead/EzInputAutocomplete';

interface EzFormProps extends PropsWithChildren {
	formName: string;
	className?: string;
	onSubmit: any;
	formData: any;
	onValidationChange: (isFormValid: boolean) => void;
}

export function EzForm({
	formName,
	className,
	children,
	onSubmit,
	onValidationChange,
	formData
}: EzFormProps) {
	const toaster = useToast();
	const [isFormValid, setIsFormValid] = useState(false);

	useEffect(() => {
		validateForm();
	}, [formData]);

	useEffect(() => {
		onValidationChange(isFormValid);
	}, [isFormValid]);

	const isValidAmountField = (element: React.ReactElement<any>) => {
		const REG_TO_CHECK_AMOUNT = /^\d*[,]*\d*$/;
		return REG_TO_CHECK_AMOUNT.test(element.props.value);
	};

	const isValidRequiredField = (element: React.ReactElement<any>) => {
		return element.props.value;
	};

	const isBetterThanZero = (element: React.ReactElement<any>) => {
		return element.props.value.replace(',','.') > 0;
	};

	const checkValidator = (
		element: React.ReactElement<any>,
		validator: EzInputValidator
	): boolean => {
		if (validator === EzInputValidator.REQUIRED)
			return isValidRequiredField(element);
		if (validator === EzInputValidator.AMOUNT)
			return isValidAmountField(element);
		if (validator === EzInputValidator.MORE_THAN_ZERO)
			return isBetterThanZero(element);
		return true;
	};

	const checkField = (element: React.ReactElement<any>): boolean => {
		return !element.props.validators.some((validator: EzInputValidator) => {
			return !checkValidator(element, validator);
		});
	};

	const validateFields = (acc: boolean, element: any) => {
		if (!isValidElement(element)) return acc;
		if (
			(element as React.ReactElement<any>).type === EzInput ||
			(element as React.ReactElement<any>).type === EzInputAutocomplete
		)
			return acc ? checkField(element) : false;
		return acc;
	};

	const validateForm = () => {
		const isValid = Children.toArray(children).reduce(validateFields, true);
		setIsFormValid(isValid);
	};

	const submitForm = (e: any) => {
		e.preventDefault();
		validateForm();
		if (isFormValid) onSubmit();
		else toaster.error('Errore: campi form non validi!');
	};

	return (
		<form
			className={className ?? ''}
			onSubmit={submitForm}
			id={formName}
			name={formName}
		>
			{children}
		</form>
	);
}
