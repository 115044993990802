import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ToasterState } from '../../om/features/toaster.state';
import { Toaster } from '../../om/toaster';

const initialState: ToasterState = {
	toasters: []
};

export const toasterSlice = createSlice({
	name: 'toaster',
	initialState,
	reducers: {
		reset: (state: ToasterState) => {
			state.toasters = [];
		},
		addToaster: (state: ToasterState, action: PayloadAction<Toaster>) => {
			state.toasters = [ ...state.toasters, action.payload ];
		},
		removeToaster: (state: ToasterState, action: PayloadAction<Toaster>) => {
			state.toasters = state.toasters.filter((toaster) => toaster.id !== action.payload.id);
		}
	}
});

export default toasterSlice.reducer;
