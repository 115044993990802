import { createAsyncThunk } from '@reduxjs/toolkit';
import authService from './auth.service';

export const loginAction = createAsyncThunk(
	'auth/login',
	async (user: any, thunkAPI) => {
		try {
			return await authService.login(user);
		} catch (error) {
			return thunkAPI.rejectWithValue('Errore servizio auth/login');
		}
	}
);

export const logoutAction = createAsyncThunk('auth/logout', async () => {
	return await authService.logout();
});

export const getProfileAction: any = createAsyncThunk('profile', async () => {
	return await authService.getProfile();
});
