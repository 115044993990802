import { useDispatch } from 'react-redux';
import { toasterSlice } from '../features/toaster/toaster.slice';
import { ToasterKind } from '../om/enum/toaster-kind.enum';
import { v4 as uuidv4 } from 'uuid';
import { Toaster } from '../om/toaster';

export const useToast = () => {
	const DEFAULT_TIMEOUT = 5000;
	const dispatch = useDispatch();

	const manageToast = (toaster: Toaster) => {
		setTimeout(() => {
			dispatch(toasterSlice.actions.removeToaster(toaster));
		}, toaster.timeout);
	};

	const success = (message: string = 'MESSAGES.GENERIC_SUCCESS', timeout: number = DEFAULT_TIMEOUT) => {
		const newToaster: Toaster = {
			id: uuidv4(),
			kind: ToasterKind.SUCCESS,
			message,
			timeout
		};
		dispatch(toasterSlice.actions.addToaster(newToaster));
		manageToast(newToaster);
	};

	const error: Function = (message: string = 'ERRORS.GENERIC', timeout: number = DEFAULT_TIMEOUT) => {
		const newToaster: Toaster = {
			id: uuidv4(),
			kind: ToasterKind.ERROR,
			message,
			timeout
		};
		dispatch(toasterSlice.actions.addToaster(newToaster));
		manageToast(newToaster);
	};

	const reset = () => {
		dispatch(toasterSlice.actions.reset());
	};

	return { error, success, reset };
};
