import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grow } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

interface EzModalProps {
	id: string;
	title?: string;
	children?: any;
	actions?: any;
	closeCustomLabel?: string;
	isOpen?: boolean;
	closeCallback?: (e?: any) => void;
}

export function EzModal({ id, title, children, actions, closeCallback, closeCustomLabel, isOpen }: EzModalProps) {
	const { t } = useTranslation();
	const Transition = React.forwardRef(function Transition(
		props: TransitionProps & {
			children: React.ReactElement<any, any>;
		},
		ref: React.Ref<unknown>
	) {
		return <Grow in={isOpen} ref={ref} {...props} />;
	});

	const handleClose = () => {
			closeCallback && closeCallback();
	};

	return (
		<Fragment>
			<Dialog open={isOpen ?? false} keepMounted onClose={handleClose} aria-describedby={id}>
				{title ? <DialogTitle>{t(title)}</DialogTitle> : null}
				<DialogContent>{children}</DialogContent>
				<DialogActions>
					{actions ?? null}
					<Button color='secondary' onClick={handleClose}>{closeCustomLabel ?? 'Chiudi'}</Button>
				</DialogActions>
			</Dialog>
		</Fragment>
	);
}
