import { FaPen, FaTrash } from 'react-icons/fa';
import { EzTableColumnType } from '../../om/enum/ez-table-column-type.enum';
import { EzTableAction } from '../../om/ez-table-action';
import { EzTableColumn } from '../../om/ez-table-column';
import useCurrency from '../../utils/currency.util';
import { useTranslation } from 'react-i18next';
import { OrderDTO } from '../../om/dto/orders/order-dto';

export function OrdersListViewTable() {
	const { t } = useTranslation();
	const { formatCurrency } = useCurrency();

	const tableColumnSettings: Array<EzTableColumn> = [
		{
			id: 'id',
			label: 'ID',
			columnType: EzTableColumnType.TEXT
		},
		{
			id: 'date',
			label: 'Data',
			columnType: EzTableColumnType.DATE
		},
		{
			id: 'client.company',
			label: 'Cliente',
			columnType: EzTableColumnType.TEXT
		},
		{
			id: 'amount',
			label: 'Importo',
			columnType: EzTableColumnType.CUSTOM,
			customColumn: (item) => {
				return (
					<div className='orders-list__amount'>
						{formatCurrency(item.amount)}
					</div>
				);
			}
		}
	];

	const tableActions = (
		onEditClick: (item: OrderDTO) => void,
		onDeleteClick: (item: OrderDTO) => void
	): Array<EzTableAction> => [
		{
			desription: t('COMMON.EDIT'),
			icon: <FaPen />,
			className: 'btn-primary',
			onClick: (item: OrderDTO) => {
				onEditClick(item);
			}
		},
		{
			desription: t('COMMON.DELETE'),
			icon: <FaTrash />,
			className: 'btn',
			onClick: (item: OrderDTO) => {
				onDeleteClick(item);
			}
		}
	];

	return { tableColumnSettings, tableActions };
}
