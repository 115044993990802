import { apiManagerUtil } from '../../utils/api-manager.util';
import crudService from '../common/crud.service.abs';

const apiManager = apiManagerUtil();

const { getAll, create, update, search, deleteAPI, getOne } = crudService('orders');

const getCounters = async () => {
	const response = await apiManager.get('orders/counters');
	return response.data;
};

const ordersService = { getAll, getCounters, create, update, search, deleteAPI, getOne };
export default ordersService;
