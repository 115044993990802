import { useState } from 'react';
import { IoChevronBack, IoChevronForward } from 'react-icons/io5';
import './ez-table-pagination.scss';
import { IconButton, MenuItem, Select } from '@mui/material';
interface EzTablePaginationProps {
	data: Array<any>;
	pagedData: Array<any>;
	start: number;
	end: number;
	onClickPrev: any;
	onClickNext: any;
	onNumItemsChange: any;
	numItemsList: Array<number>;
}

export function EzTablePagination({ data, pagedData, start, end, onClickNext, onClickPrev, onNumItemsChange, numItemsList }: EzTablePaginationProps) {
	const startLabel = start + 1;
	const [ numItemsSelected, setNumItemsSelected ] = useState(numItemsList[0]);

	const updateNumItems = (e: any) => {
		setNumItemsSelected(e.target.value);
		onNumItemsChange(e.target.value);
	};

	const renderIndicator = () => {
		return (
			<div className="ez-table-pagination__indicator">
				{startLabel}-{end < data.length ? end : data.length} di {data.length} Elementi
			</div>
		);
	};

	const renderContentStart = () => {
		return (
			<div className="ez-table-pagination__content-start">
				<Select value={numItemsSelected} onChange={updateNumItems} className="ez-table-pagination__select-num-items">
					{numItemsList.map((option, index) => <MenuItem value={option}>{option}</MenuItem>)}
				</Select>
				{renderIndicator()}
			</div>
		);
	};

	const renderArrows = (): JSX.Element | null => {
		return totalPages() > 1 ? (
			<div className="ez-table-pagination__nav-arrows">
				<IconButton color="tprimary" onClick={onClickPrev} className={`ez-table-pagination__arrow`} disabled={startLabel === 1}>
					<IoChevronBack size={20} />
				</IconButton>
				<IconButton color="tprimary" onClick={onClickNext} className={`ez-table-pagination__arrow`} disabled={end >= data.length}>
					<IoChevronForward size={20} />
				</IconButton>
			</div>
		) : null;
	};

	const totalPages = (): number => {
		return Math.ceil(data.length / numItemsSelected);
	};

	const renderPageLabel = (): JSX.Element | null => {
		return totalPages() > 1 ? <div className="ez-table-pagination__page-label">{`Pagina ${end / numItemsSelected} di ${totalPages()}`}</div> : null;
	};

	const renderContentEnd = () => {
		return (
			<div className="ez-table-pagination__content-end">
				{renderPageLabel()}
				{renderArrows()}
			</div>
		);
	};

	return (
		<div className="ez-table-pagination">
			{renderContentStart()}
			{renderContentEnd()}
		</div>
	);
}
