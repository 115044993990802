import { IoBarChartOutline, IoTrophyOutline } from 'react-icons/io5';
import { MdOutlineBusinessCenter, MdOutlineGroup, MdOutlineHouse } from 'react-icons/md';
import { MenuItem } from '../../../om/menu-item';

const ICON_SIZE: number = 24;

export const NAVBAR_MENU: Array<MenuItem> = [
	{ title: 'Dashboard', icon: <MdOutlineHouse size={ICON_SIZE} />, path: '/dashboard' },
	{
		title: 'Ordini',
		icon: <MdOutlineBusinessCenter size={ICON_SIZE} />,
		path: '/orders'
	},
	{
		title: 'Clienti',
		icon: <MdOutlineGroup size={ICON_SIZE} />,
		path: '/clients',
		hasSubmenu: true,
		submenuItems: [ { title: 'Elenco clienti' }, { title: 'Nuovo cliente' } ]
	},
	{ title: 'Targets', icon: <IoTrophyOutline size={ICON_SIZE} />, path: '/targets' },
	{ title: 'Statistiche', icon: <IoBarChartOutline size={ICON_SIZE} />, path: '/stats' }
];
