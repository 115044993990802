import './ez-success-feedback.scss';
import checkGif from '../../../assets/images/check.gif';

export interface EzSuccessFeedbackProps {
  message: string;
  title?: string;
}

export function EzSuccessFeedback({message, title}: EzSuccessFeedbackProps) {
  return (
    <div className="ez-success-feedback">
      <img className="ez-success-feedback__gif" src={checkGif} />
      {title && <h1 className="ez-success-feedback__title">OK!</h1>}
      <h3 className="ez-success-feedback__message">{message}</h3>
    </div>
  );
}
