import moment from 'moment';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {EzSelect} from '../../../../components/core/ez-select/EzSelect';
import {EzTable} from '../../../../components/core/ez-table/EzTable';
import {EzToolbar} from '../../../../components/core/ez-toolbar/EzToolbar';
import {getClientsOrdersPerMonthAction} from '../../../../features/stats/stats.actions';
import {useAppDispatch, useAppSelector} from '../../../../hooks/redux-toolkit.hook';
import {EzTableColumnType} from '../../../../om/enum/ez-table-column-type.enum';
import {FilterPeriod} from '../../../../om/enum/filter-period.enum';
import {EzTableColumn} from '../../../../om/ez-table-column';
import useCurrency from '../../../../utils/currency.util';
import {tableUtil} from '../../../../utils/table.util';
import './clients-orders-per-month.scss';

export function ClientsOrderPerMonth() {
  const prefix = 'clients-order-per-month';
  const {t} = useTranslation();
  const {clientsOrdersPerMonth: response} = useAppSelector((state: any) => state.stats);
  const dispatch = useAppDispatch();
  const {formatCurrency} = useCurrency();
  const {filterSearchData} = tableUtil();
  const [clientsStatsFiltered, setClientsStatsFiltered] = useState<any>([]);
  const [filterPeriod, setFilterPeriod] = useState(FilterPeriod.PER_MONTH);

  useEffect(() => {
    dispatch(getClientsOrdersPerMonthAction(new Date()));
  }, []);

  const getMonthColumns = (): Array<EzTableColumn> => {
    const engMonths = moment.localeData('en').months();
    if (filterPeriod == FilterPeriod.PER_MONTH)
      return moment.months().map((month, index) => ({
        id: month.toLowerCase(),
        label: month.toUpperCase(),
        columnType: EzTableColumnType.CUSTOM,
        customColumn: (item) => {
          return (
            <div className='text-right pr-2'>
              {formatCurrency(
                item[engMonths[index].toLowerCase()] ? item[engMonths[index].toLowerCase()].totalAmount : 0
              )}
            </div>
          );
        },
      }));
    return [];
  };

  const getTrimesterColumns = (): Array<EzTableColumn> => {
    return ['first', 'second', 'third', 'last'].map((trimester: string) => ({
      id: trimester,
      label: '' + t(`STATS.TRIMESTER.${trimester.toUpperCase()}`),
      columnType: EzTableColumnType.CUSTOM,
      customColumn: (item: any) => <div className='text-right w-20'>{formatCurrency(item[trimester])}</div>,
    }));
  };

  const tableColumnSettings: Array<EzTableColumn> = [
    {
      id: 'code',
      label: 'Cod.',
      sticky: true,
      columnType: EzTableColumnType.TEXT,
    },
    {
      id: 'company',
      label: 'Ragione sociale',
      sticky: true,
      stickyLeftWidth: '4.5rem',
      columnType: EzTableColumnType.CUSTOM,
      customColumn: (item) => {
        return (
          <div>
            <div className='text-ellipsis w-80 overflow-hidden'>{item.company}</div>
          </div>
        );
      },
      order: 'center',
    },
    ...(filterPeriod == FilterPeriod.PER_MONTH ? getMonthColumns() : getTrimesterColumns()),
  ];

  const searchClients = (valueToSearch?: string) => {
    setClientsStatsFiltered({...clientsStatsFiltered, search: valueToSearch});
  };

  const _getAmount = (...monthsSum: any[]): number => {
    console.log(monthsSum);
    return monthsSum.reduce((acc, curr) => (curr ? acc + curr?.totalAmount || 0 : acc), 0);
  };

  const _getStatsByTrimester = (clientsToDisplay: any[]): any[] => {
    return clientsToDisplay.reduce(
      (
        acc,
        {
          id,
          code,
          company,
          january,
          february,
          march,
          april,
          may,
          june,
          july,
          august,
          september,
          october,
          november,
          december,
        }
      ) => {
        console.log(april);
        return [
          ...acc,
          {
            first: _getAmount(january, february, march),
            second: _getAmount(april, may, june),
            third: _getAmount(july, august, september),
            last: _getAmount(october, november, december),
            id,
            code,
            company,
          },
        ];
      },
      []
    );
  };

  const getClientsData = () => {
    let clientsToDisplay: any[] = response?.clientsOrdersPerMonth ?? [];
    if (clientsStatsFiltered?.search && '' !== clientsStatsFiltered?.search)
      clientsToDisplay = filterSearchData(clientsToDisplay, clientsStatsFiltered);
    if (filterPeriod == FilterPeriod.PER_TRIMESTER) return _getStatsByTrimester(clientsToDisplay);
    return clientsToDisplay;
  };

  const renderFilters = () => {
    return (
      <div className='flex items-center'>
        <EzSelect
          name='kind'
          value={filterPeriod}
          onChange={(e: any) => setFilterPeriod(e.target.value)}
          options={Object.values(FilterPeriod)
            .filter((kind) => typeof kind === 'string')
            .map((kind, index) => ({
              value: index,
              description: t(`STATS.PERIOD_FILTERS.${kind.toString()}`),
            }))}
          size='small'
        />
      </div>
    );
  };

  return (
    <div className={prefix}>
      <p className={`${prefix}__label`}>{t('STATS.CLIENTS.ORDERS_PER_MONTH.LABEL')}</p>
      <div className={`${prefix}__table-head`}>
        <EzToolbar onSearch={searchClients} contentEnd={renderFilters()} />
      </div>
      <EzTable
        id='clients-orders-per-month'
        columns={tableColumnSettings}
        data={getClientsData()}
        customHeadingClasses='whitespace-nowrap'
      />
    </div>
  );
}
