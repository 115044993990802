export const momentITLocale = {
	months: 'Gennaio_Febbraio_Marzo_Aprile_Maggio_Giugno_Luglio_Agosto_Settembre_Ottobre_Novembre_Dicembre'.split('_'),
	monthsShort: 'Gen_Feb_Mar_Apr_Mag_Giu_Lug_Ago_Set_Ott_Nov_Dic'.split('_'),
	monthsParseExact: true,
	weekdays: 'domenica_lunedì_martedì_mercoledì_giovedì_venerdì_sabato'.split('_'),
	weekdaysShort: 'dom._lun._mar._mer._gio._ven._sab.'.split('_'),
	weekdaysMin: 'Do_Lu_Ma_Me_Gi_Ve_Sa'.split('_'),
	weekdaysParseExact: true,
	longDateFormat: {
		LT: 'HH:mm',
		LTS: 'HH:mm:ss',
		L: 'DD/MM/YYYY',
		LL: 'D MMMM YYYY',
		LLL: 'D MMMM YYYY HH:mm',
		LLLL: 'dddd D MMMM YYYY HH:mm'
	},
	calendar: {
		sameDay: '[Oggi alle] LT',
		nextDay: '[Ddomani alle] LT',
		nextWeek: 'dddd [alle] LT',
		lastDay: '[Ieri alle] LT',
		lastWeek: 'dddd [Settimana scorsa alle] LT',
		sameElse: 'L'
	},
	dayOfMonthOrdinalParse: /\d{1,2}(er|e)/,
	ordinal: function(number: any) {
		return number + (number === 1 ? 'er' : 'e');
	},
	meridiemParse: /PD|MD/,
	isPM: function(input: any) {
		return input.charAt(0) === 'M';
	},
	meridiem: function(hours: any, minutes: any, isLower: any) {
		return hours < 12 ? 'PD' : 'MD';
	},
	week: {
		dow: 1,
		doy: 4
	}
};
