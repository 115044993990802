import { t } from 'i18next';
import moment from 'moment';
import { IoWarningOutline } from 'react-icons/io5';
import { EzModal } from '../../../components/core/ez-modal/EzModal';
import { deleteTargetAction } from '../../../features/targets/targets.actions';
import { useAppDispatch } from '../../../hooks/redux-toolkit.hook';
import { useToast } from '../../../hooks/toaster.hook';
import { TargetDTO } from '../../../om/dto/targets/target.dto';

export interface DeleteTargetModalProps {
  modalId: string;
  callback: any;
  targetToDelete: TargetDTO | null;
  isOpen: boolean;
  closeModalCallback: () => void;
}

export const DeleteTargetModal = ({
  modalId,
  callback,
  targetToDelete,
  isOpen,
  closeModalCallback,
}: DeleteTargetModalProps) => {
  const toaster = useToast();
  const dispatch = useAppDispatch();
  const dispatchDeleteTarget = async (e: any) => {
    e.preventDefault();
    const result = await dispatch(deleteTargetAction(targetToDelete?.id));
    if (result.error) toaster.error(result.error?.error);
    else {
      callback();
      toaster.success('Target eliminato con successo!');
      closeModalCallback();
    }
  };

  const deleteTargetModalActions = () => (
    <button onClick={dispatchDeleteTarget} className="btn btn-primary w-32">
      {t('COMMON.CONFIRM')}
    </button>
  );

  return (
    <EzModal
      isOpen={isOpen}
      id={modalId}
      actions={deleteTargetModalActions()}
      closeCustomLabel="Annulla"
      closeCallback={closeModalCallback}
    >
      <p className="py-4 text-center">
        <div className="flex justify-center mb-5">
          <IoWarningOutline className="text-warning" size={'5rem'} />
        </div>
        <div className="text-lg">Sei sicuro di voler eliminare il target?</div>
        <br />
        <div className="flex flex-col text-start mt-5 px-5">
          <div>
            Id: <b>#{targetToDelete?.id}</b>
          </div>
          <div>
            Descrizione: <b>{targetToDelete?.description}</b>
          </div>
          <div>
            Data inizio:{' '}
            <b>{moment(targetToDelete?.startDate).format('DD MMM YYYY')}</b>
          </div>
          <div>
            Data fine:{' '}
            <b>{moment(targetToDelete?.endDate).format('DD MMM YYYY')}</b>
          </div>
        </div>
        <b></b>
      </p>
    </EzModal>
  );
};
