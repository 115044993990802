export default function useCurrency(currencyDisplay = 'symbol') {
  const currency = 'EUR';

  const currencyFormatter = new Intl.NumberFormat('it-IT', {
    style: 'currency',
    currency,
    currencyDisplay,
  });

  const compactCurrencyFormatter = new Intl.NumberFormat('en-US', {
    notation: 'compact',
    compactDisplay: 'short',
    style: 'currency',
    currency,
  });

  const formatCurrency = (value: number, style = 'currency'): string => {
    return currencyFormatter.format(value);
  };

  const formatCompactCurrency = (value: number) => {
    return compactCurrencyFormatter.format(value);
  };

  return { formatCurrency, formatCompactCurrency };
}
