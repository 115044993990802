export function tableUtil() {
  const filterSearchData = (dataToDisplay: any[], filterList: any) =>
    dataToDisplay.filter((data) => doFilterSearch(data, filterList));

  const doFilterSearch = (data: any, filterList: any) => {
    return Object.values(data).some((value: any) =>
      value?.toString().toLowerCase().includes(filterList.search.toLowerCase())
    );
  };

  const filterField = (data: any, filtersList: any, key: string): boolean =>
    data[key]
      .toString()
      .toLowerCase()
      .includes(filtersList[key].toString().toLowerCase());

  return { doFilterSearch, filterField, filterSearchData };
}
