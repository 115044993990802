import {createAsyncThunk} from '@reduxjs/toolkit';

export default function crudActions(path: string, service: any) {
  const getAllAction: any = createAsyncThunk(path, async (filters?: any) => {
    return await service.getAll(filters);
  });

  const createAction: any = createAsyncThunk('create' + path, async (input) => {
    return await service.create(input);
  });

  const deleteAction: any = createAsyncThunk('delete' + path, async (input) => {
    return await service.deleteAPI(input);
  });

  const updateAction: any = createAsyncThunk('update' + path, async ({id, body}: {id: number; body: any}) => {
    return await service.update(id, body);
  });

  const searchAction: any = createAsyncThunk('search' + path + '/search', async (body) => {
    return await service.search(body);
  });

  const getOneAction: any = createAsyncThunk('getOne' + path, async (id) => {
    return await service.getOne(id);
  });

  return {getAllAction, deleteAction, createAction, updateAction, searchAction, getOneAction};
}
