import moment, {Moment} from 'moment';

export default function CalendarUtil() {
  let calendarArray: Array<Map<number, Moment>> = [];

  const _getEndOfMonth = (dateReference: Date = new Date()) =>
    moment(dateReference).endOf('month').startOf('day');

  const getCalendarArray = (
    dateReference: Date = new Date()
  ): Array<Map<number, Moment>> => {
    calendarArray = [];
    for (
      let day: Moment = moment(dateReference).startOf('month'),
        localWeekDays: Map<number, any> = new Map();
      day.isSameOrBefore(_getEndOfMonth(dateReference));
      day.add(1, 'days')
    ) {
      localWeekDays = addDays(moment(day), localWeekDays);
      ifSundayUpdateCalendar(dateReference, day, localWeekDays, () => {
        localWeekDays = new Map();
      });
    }
    return calendarArray;
  };

  const addDays = (day: Moment, localWeekDays: Map<number, any>) => {
    localWeekDays.set(day.weekday(), day);
    return localWeekDays;
  };

  const ifSundayUpdateCalendar = (
    dateReference: Date = new Date(),
    day: Moment,
    localWeekDays: Map<number, any>,
    ifSundayResetLocalWeekDays: Function
  ) => {
    if (
      localWeekDays.size > 0 &&
      (day.weekday() === 6 ||
        day.startOf('day').isSame(_getEndOfMonth(dateReference)))
    ) {
      calendarArray.push(localWeekDays);
      ifSundayResetLocalWeekDays();
    }
  };

  const formatDate = (dateToBeFormatted?: Date): string | null => {
    if (dateToBeFormatted)
      return moment(dateToBeFormatted).format('yyyy-MM-DD');
    return null;
  };

  const getWeekDays = (dateReference: Date = new Date()) =>
    [0, 1, 2, 3, 4, 5, 6].map((day) =>
      moment(dateReference).locale('it').weekday(day)
    );

  return {getCalendarArray, formatDate, getWeekDays};
}
