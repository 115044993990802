import { useEffect } from 'react';

export function useOutsideClickHandler(ref: any, callbackOutside: any) {
	useEffect(
		() => {
			function handleClickOutside(event: any) {
				if (ref.current && !ref.current.contains(event.target)) {
					callbackOutside && callbackOutside(event);
				}
			}
			document.addEventListener('mousedown', handleClickOutside);
			return () => {
				document.removeEventListener('mousedown', handleClickOutside);
			};
		},
		[ ref, callbackOutside ]
	);
}
