import {apiManagerUtil} from '../../utils/api-manager.util';

const apiManager = apiManagerUtil();

const getSumPerDay = async (referenceDate: Date) => {
  const response = await apiManager.get('stats/sumPerDayCalendar', {
    referenceDate,
  });
  return response.data;
};

const getClientsOrdersPerMonth = async (dateReference: Date) => {
  const response = await apiManager.get('stats/clientsOrdersPerMonth', {dateReference});
  return response.data;
};

const statsService = {getSumPerDay, getClientsOrdersPerMonth};
export default statsService;
