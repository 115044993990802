import { Chip } from '@mui/material';
import { useTranslation } from 'react-i18next';

export interface EzFilterChipsInterface {
	filtersList: any;
	filtersToIgnore: string[];
	page?: string;
	onChipDelete: (filterToDelete: string) => void;
}

const prefix = 'ez-filter-chips';

export function EzFilterChips({
	filtersList,
	filtersToIgnore,
	page,
	onChipDelete
}: EzFilterChipsInterface) {
	const { t } = useTranslation();
	const REG_MATCH_UPPERCASE_LETTERS: RegExp = /([A-Z]{1})/gm;

	const renderFiltersChip = () =>
		Object.keys(filtersList).map((filter) =>
			'' !== filtersList[filter] && !filtersToIgnore.includes(filter)
				? renderChip(filter, filtersList[filter])
				: null
		);

	const renderChip = (filter: string, value: string) => (
		<Chip
			label={renderChipContentContainer(filter, value)}
			onDelete={() => {
				onChipDelete && onChipDelete(filter);
			}}
		/>
	);

	const renderChipContentContainer = (filter: string, value: string) => (
		<div className={`${prefix}__filter flex gap-1`}>
			{renderChipPrefix(filter)} {renderChipValue(value)}
		</div>
	);

	const renderChipPrefix = (filter: string) => (
		<div className={`${prefix}__filter-prefix font-semibold`}>
			{`${getChipPrefixTranslated(filter)}: `}
		</div>
	);

	const getChipPrefixTranslated = (filter: string) =>
		t(
			`${page}.FILTERS.${filter.replace(
				REG_MATCH_UPPERCASE_LETTERS,
				'_$1'
			)}`.toUpperCase()
		);

	const renderChipValue = (value: string) => (
		<div className={`${prefix}__filter-value`}>{value}</div>
	);

	return (
		<div className={`${prefix} flex mb-3 gap-2`}>
			{filtersList ? renderFiltersChip() : null}
		</div>
	);
}
