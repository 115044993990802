import { t } from 'i18next';
import { EzModal } from '../../../components/core/ez-modal/EzModal';
import { useAppDispatch } from '../../../hooks/redux-toolkit.hook';
import { useToast } from '../../../hooks/toaster.hook';
import { OrderDTO } from '../../../om/dto/orders/order-dto';
import moment from 'moment';
import useCurrency from '../../../utils/currency.util';
import { IoWarningOutline } from 'react-icons/io5';
import { deleteOrderAction } from '../../../features/orders/orders.actions';
import { Button } from '@mui/material';

export interface DeleteOrderModalProps {
  modalId: string;
  isOpen: boolean;
  callback: any;
  onClose: any;
  orderToDelete: OrderDTO | null;
}

export const DeleteOrderModal = ({ modalId, callback, orderToDelete, isOpen, onClose }: DeleteOrderModalProps) => {
  const toaster = useToast();
  const dispatch = useAppDispatch();
  const {formatCurrency} = useCurrency('code')
  const dispatchDeleteOrder = async (e: any) => {
    e.preventDefault();
    const result = await dispatch(deleteOrderAction(orderToDelete?.id));
    if (result.error) toaster.error(result.error?.error);
    else {
      callback();
      toaster.success(`L'ordine #${orderToDelete?.id} del ${moment(orderToDelete?.date).format('DD MMMM YYYY')} del cliente ${orderToDelete?.client?.company} è stato eliminato con successo`);
    }
  };

  const deleteOrderModalActions = () => (
    <Button variant="contained" onClick={dispatchDeleteOrder} className="btn btn-primary w-32">
      {t('COMMON.CONFIRM')}
    </Button>
  );

  return (
    <EzModal isOpen={isOpen} id={modalId} actions={deleteOrderModalActions()} closeCustomLabel='Annulla' closeCallback={onClose}>
      <p className="py-4 text-center">
        <div className="flex justify-center mb-5">
          <IoWarningOutline className='text-warning' size={'5rem'} />
        </div>
        <div className="text-lg">Sei sicuro di voler eliminare l'ordine?</div>
        <br />
        <div className="flex flex-col text-start mt-5 px-5">
          <div>Id: <b>#{orderToDelete?.id}</b></div>
          <div>Data: <b>{moment(orderToDelete?.date).format('DD MMM YYYY')}</b></div>
          <div>Cliente: <b>{orderToDelete?.client?.company}</b></div>
          <div>Importo: <b>{formatCurrency(Number(orderToDelete?.amount))}</b></div>
        </div>
        <b>
        </b>
      </p>
    </EzModal>
  );
};
