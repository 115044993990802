import moment from 'moment';
import './calendar-spd-pagination.scss';
import {IconButton} from '@mui/material';
import {IoChevronBack, IoChevronForward} from 'react-icons/io5';

interface CalendarSpdPaginationProps {
  dateReference: Date;
  onDateChange: (arrow: 'next' | 'prev') => any;
}

export const CalendarSpdPagination = ({
  dateReference,
  onDateChange,
}: CalendarSpdPaginationProps) => {
  const onArrowClick = (arrow: 'next' | 'prev') => {
    onDateChange(arrow);
  };

  const isEqualOrBeforeCurrentMonth: boolean =
    moment(dateReference).diff(moment(), 'months') !== 0;
  return (
    <div className='calendar-spd-pagination__nav-arrows'>
      <IconButton
        color='tprimary'
        onClick={onArrowClick.bind(this, 'prev')}
        className={`calendar-spd-pagination__arrow`}
        key='previous'
      >
        <IoChevronBack size={20} />
      </IconButton>
      <IconButton
        color='tprimary'
        onClick={onArrowClick.bind(this, 'next')}
        className={`calendar-spd-pagination__arrow`}
        disabled={!isEqualOrBeforeCurrentMonth}
        key='next'
      >
        <IoChevronForward size={20} />
      </IconButton>
    </div>
  );
};
