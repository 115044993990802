import moment from 'moment';
import { EzTableColumnType } from '../../../../om/enum/ez-table-column-type.enum';
import { EzTableColumn } from '../../../../om/ez-table-column';

import './ez-table-item.scss';
import { TableCell } from '@mui/material';

export interface EzTableItemProps {
  item: any;
  columnInfo: EzTableColumn;
}

export function EzTableItem({ item, columnInfo, dateFormat }: any) {
  const getContent = (): string => {
    if (columnInfo.id.includes('.')) {
      const columnContent = columnInfo.id.split('.');
      return item[columnContent[0]][columnContent[1]];
    }
    return item[columnInfo.id];
  };

  const getDateCellTemplate = () => {
    if (columnInfo.dateFormat)
      return moment(getContent()).format(columnInfo.dateFormat);
    if (moment(getContent()).format('DDMMYYYY') === moment().format('DDMMYYYY'))
      return 'OGGI';
    if (
      moment().subtract(1, 'days').format('DDMMYYYY') ===
      moment(getContent()).format('DDMMYYYY')
    )
      return 'IERI';
    return (
      <div className="ez-table-item__date-cell">
        {moment(getContent()).format('DD MMM YYYY').toUpperCase()}
      </div>
    );
  };

  const styles = {
    ...(columnInfo.stickyLeftWidth && { left: columnInfo.stickyLeftWidth }),
  };

  return (
    <TableCell
      className={`ez-table-item ${
        columnInfo.sticky ? 'ez-table-item--sticky' : null
      }`}
      key={item.id}
      style={styles}
    >
      {columnInfo.columnType === EzTableColumnType.DATE
        ? getDateCellTemplate()
        : columnInfo.columnType === EzTableColumnType.CUSTOM
        ? columnInfo.customColumn(item)
        : getContent()}
    </TableCell>
  );
}
