import { createAsyncThunk } from '@reduxjs/toolkit';
import crudActions from '../common/crud.actions.abs';
import ordersService from './orders.service';
import { OrderDTO } from '../../om/dto/orders/order-dto';

const path = 'orders';

const { getAllAction, searchAction, getOneAction, deleteAction } = crudActions(
  path,
  ordersService
);

const createOrderAction: any = createAsyncThunk(
  'create' + path,
  async (input: OrderDTO | any) => {
    return await ordersService.create({
      ...input,
      amount: +input.amount.replace(',', '.'),
    });
  }
);

const updateOrderAction: any = createAsyncThunk(
  'update' + path,
  async ({ id, body }: { id: number; body: any }) => {
    return await ordersService.update(id, {
      ...body,
      amount: +body.amount.replace(',', '.'),
    });
  }
);

const getCountersAction = createAsyncThunk(
  'orders/counters',
  async (payload, thunkAPI) => {
    try {
      return await ordersService.getCounters();
    } catch (error) {
      return thunkAPI.rejectWithValue('Errore servizio orders/counters');
    }
  }
);

export {
  createOrderAction,
  deleteAction as deleteOrderAction,
  getAllAction,
  getCountersAction,
  getOneAction as getOneOrderAction,
  searchAction as searchOrderAction,
  updateOrderAction,
};
