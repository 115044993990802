export const inputUtil = () => {
	const getUpdatedValue = (e: any, prevState: any) => ({
		...prevState,
		[e.target.name]: e.target.value
	});

	const getCustomUpdatedValue = (e: any, prevState: any, name: string) => ({
		...prevState,
		[name]: e
	});

	return { getUpdatedValue, getCustomUpdatedValue };
};
