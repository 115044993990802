import {PropsWithChildren, Children, useState, Fragment} from 'react';
import './ez-nav-group.scss';
import {useTranslation} from 'react-i18next';

export interface EzNavGroupProps extends PropsWithChildren {
  navItems: Array<string>;
  translatePrefix?: string;
}

export function EzNavGroup({navItems, children, translatePrefix}: EzNavGroupProps) {
  const prefix = 'ez-nav-group';
  const [activeItem, setActiveItem] = useState(navItems[0]);
  const {t} = useTranslation();

  const setAsActiveItem = (item: string) => {
    setActiveItem(item);
  };

  const renderActiveItem = () => {
    const activeItemIndex: number = Children.toArray(children).findIndex(
      (navItem: any) => navItem.props.id === activeItem
    );
    return Children.toArray(children)[activeItemIndex];
  };

  return (
    <Fragment>
      <div className={prefix}>
        {navItems.map((item) => (
          <div
            onClick={() => setAsActiveItem(item)}
            className={activeItem === item ? `${prefix}__nav-item-active` : `${prefix}__nav-item`}
          >
            {translatePrefix ? t(`${translatePrefix}.${item.toUpperCase()}`) : item}
          </div>
        ))}
      </div>
      <div className={`${prefix}__content`}>{renderActiveItem()}</div>
    </Fragment>
  );
}
