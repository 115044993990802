import { OrderState } from '../../om/features/order.state';
import { OrdersState } from '../../om/features/orders.state';
import { createOrderAction, getAllAction, getCountersAction, getOneOrderAction } from './orders.actions';

export const getAllBuilders = (builder: any): any => {
	builder
		.addCase(getAllAction.pending, (state: OrderState) => {
			state.isOrdersLoading = true;
		})
		.addCase(getAllAction.rejected, (state: OrderState, action: any) => {
			state.isOrdersLoading = false;
		})
		.addCase(getAllAction.fulfilled, (state: OrderState, action: any) => {
			state.isOrdersLoading = false;
			state.orders = action.payload;
		});
};

export const getCountersBuilders = (builder: any): any => {
	builder
		.addCase(getCountersAction.pending, (state: OrdersState) => {
			state.counters = null;
			state.isGetCountersLoading = true;
		})
		.addCase(getCountersAction.rejected, (state: OrdersState) => {
			state.counters = null;
			state.isGetCountersLoading = false;
		})
		.addCase(getCountersAction.fulfilled, (state: OrdersState, action: any) => {
			state.counters = action.payload;
			state.isGetCountersLoading = false;
		});
	return builder;
};

export const createOrderBuilders = (builder: any): any =>
builder
	.addCase(createOrderAction.pending, (state: OrderState) => {
		state.isCreateOrderSuccess = false;
	})
	.addCase(createOrderAction.rejected, (state: OrderState, action: any) => {
		state.isCreateOrderSuccess = false;
	})
	.addCase(createOrderAction.fulfilled, (state: OrderState, action: any) => {
		state.isCreateOrderSuccess = true;
	});


export const getOneOrderBuilders = (builder:any):any => 
builder
	.addCase(getOneOrderAction.pending, (state: OrderState) => {
		state.isOrderDetailsLoading = true;
		state.orderDetails = null;
	})
	.addCase(getOneOrderAction.rejected, (state: OrderState, action: any) => {
		state.isOrderDetailsLoading = false;
		state.orderDetails = null;
	})
	.addCase(getOneOrderAction.fulfilled, (state: OrderState, action: any) => {
		state.isOrderDetailsLoading = false;
		state.orderDetails = action.payload;
	});
