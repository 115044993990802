import {configureStore} from '@reduxjs/toolkit';
import {authSlice} from '../features/auth/auth.slice';
import {toasterSlice} from '../features/toaster/toaster.slice';
import {statsSlice} from '../features/stats/stats.slice';
import {ordersSlice} from '../features/orders/orders.slice';
import {clientsSlice} from '../features/clients/clients.slice';
import {targetsSlice} from '../features/targets/targets.slice';

export const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    toaster: toasterSlice.reducer,
    stats: statsSlice.reducer,
    orders: ordersSlice.reducer,
    clients: clientsSlice.reducer,
    targets: targetsSlice.reducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
