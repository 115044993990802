import { useEffect, useRef, useState } from 'react';
import './ez-input-autocomplete.scss';
import { useOutsideClickHandler } from '../../../hooks/outside-click-handler.hook';
import { KeyboardEnum } from '../../../om/enum/keyboard.enum';
import { useTranslation } from 'react-i18next';
import { BeatLoader } from 'react-spinners';
import { EzErrorsLabel } from '../ez-errors-label/EzErrorsLabel';
import { CommonEzInput } from '../../../om/common-ez-input';

interface EzInputAutocompleteProps extends CommonEzInput {
	minCharacters?: number;
	data: Array<any>;
	labelKey?: string;
	valueKey?: string;
	required?: boolean;
	value?: any;
	onChange?: any;
	isAsync?: boolean;
	isLoading?: boolean;
  maxBoxHeight?: number;
	asyncSearchCallback?: (query: string) => void;
}

export function EzInputAutocomplete({ label, placeholder, data, labelKey, valueKey, required, value, onChange, name, isAsync, asyncSearchCallback, minCharacters, isLoading, validators, maxBoxHeight }: EzInputAutocompleteProps) {
	const [ query, setQuery ] = useState('');
	const [ showFloatingBox, setShowFloatingBox ] = useState(false);
	const [ keyboardSelectedItem, setKeyboardSelectedItem ] = useState(null) as any;
	const [ isTouched, setIsTouched ] = useState(false);
	const refWrapper = useRef(null);
	const { t } = useTranslation();

	const onTyping = (e: any) => {
		if (!isTouched) setIsTouched(true);
		!showFloatingBox && setShowFloatingBox(true);
		onChangeSelectedItem(null);
		setQuery(e.target.value);
		isAsync && asyncSearchCallback && asyncSearchCallback(e.target.value);
	};

	const handleArrowUp = (e: any) => {
		if (e.key === KeyboardEnum.ARROW_UP) {
			const nextSelectedItem: number = keyboardSelectedItem ? keyboardSelectedItem - 1 : getFilteredData().length;
			setKeyboardSelectedItem(keyboardSelectedItem !== null ? nextSelectedItem : getFilteredData().length);
		}
	};

	const handleArrowDown = (e: any) => {
		if (e.key === KeyboardEnum.ARROW_DOWN) {
			const nextSelectedItem: number = keyboardSelectedItem !== getFilteredData().length ? keyboardSelectedItem + 1 : 0;
			setKeyboardSelectedItem(keyboardSelectedItem !== null ? nextSelectedItem : 0);
		}
	};

	const handleEnter = (e: any) => {
		if (e.key === KeyboardEnum.ENTER) {
			const foundItem = getFilteredData().find((item, index) => index === keyboardSelectedItem);
			onChangeSelectedItem(foundItem);
			setKeyboardSelectedItem(-1);
			setQuery(getItemLabel(foundItem));
			closeFloatingBox();
		}
	};

	const handleDelete = (e: any) => {
		if ([ KeyboardEnum.BACKSPACE, KeyboardEnum.DELETE ].includes(e.key)) {
			setKeyboardSelectedItem(-1);
			onChangeSelectedItem(null);
		}
	};

	const keyButtonsHandler = (e: any) => {
		handleArrowDown(e);
		handleArrowUp(e);
		handleEnter(e);
		handleDelete(e);
	};

  useEffect(() => {
    const test = document.querySelector('.ez-input-autocomplete__item--hovered');
    if(test)
    test.scrollIntoView({ behavior: 'smooth' })
  }, [keyboardSelectedItem])

	const onInputFocus = () => {
		setShowFloatingBox(true);
	};

	const closeFloatingBox = () => {
		setShowFloatingBox(false);
	};
	useOutsideClickHandler(refWrapper, closeFloatingBox);

	const getItemLabel = (item: any) => {
		return labelKey ? item[labelKey] : item.label;
	};

	const getItemValue = (item: any) => {
		if (item) return valueKey ? item[valueKey] : item.value;
		return null;
	};

	const onItemClick = (e: any, value: any) => {
		e.preventDefault();
		const foundItem = getFilteredData().find((item) => getItemValue(item) === value);
		setKeyboardSelectedItem(-1);
		onChangeSelectedItem(foundItem);
		setQuery(getItemLabel(foundItem));
		closeFloatingBox();
	};

	const onChangeSelectedItem = (foundItem: any) => {
		onChange(foundItem);
	};

	const getFilteredData = (): Array<any> => {
		return [ ...(data ? data : []) ].filter((item) => getItemLabel(item).toUpperCase().includes(query.toUpperCase()));
	};

	const getTextSearch = (item: any) => {
		return getItemLabel(item).toUpperCase();
	};

	const getFirstPartTextSearch = (item: any) => {
		return getTextSearch(item).slice(0, getTextSearch(item).indexOf(query.toUpperCase()));
	};

	const getLastPartTextSearch = (item: any) => {
		return getTextSearch(item).slice(getTextSearch(item).indexOf(query.toUpperCase()) + query.length, getTextSearch(item).length);
	};

	const showItemLabel = (item: any) => (
		<span>
			{getFirstPartTextSearch(item)}
			<strong>{query.toUpperCase()}</strong>
			{getLastPartTextSearch(item)}
		</span>
	);

	useEffect(() => {
		setQuery(value ? getItemLabel(value) : '')
	}, [value])

	return (
		<div className="ez-input-autocomplete">
			{label && (
				<label className="label">
					<span className="label-text">{label}</span>
				</label>
			)}
			<input autoComplete='off' className="ez-input-autocomplete__text-box" name={name} type="text" required={required} placeholder={placeholder} value={query} onChange={onTyping} onKeyDown={keyButtonsHandler} onClick={onInputFocus} />
			{showFloatingBox ? (
				<ul ref={refWrapper} className="ez-input-autocomplete__floating-box" style={{maxHeight: `${maxBoxHeight || 16}rem`}}>
					{minCharacters && minCharacters > 1 && query?.length < minCharacters ? (
						<li className="ez-input-autocomplete__min-characters-box">{t('UI.EZ_AUTOCOMPLETE.MIN_CHAR_REQUIRED', { minCharacters })}</li>
					) : isLoading ? (
						<div className="ez-input-autocomplete__spinner-container">
							<BeatLoader color="0" className="ez-input-autocomplete__spinner" />
						</div>
					) : (
						data &&
						getFilteredData().map((item, index) => (
							<li>
								<div
									key={index}
									className={`ez-input-autocomplete__item ${index === keyboardSelectedItem && 'ez-input-autocomplete__item--hovered'} ${getItemValue(item) === getItemValue(value) && 'active'}`}
									onClick={(e: any) => onItemClick(e, getItemValue(item))}
								>
									{query ? showItemLabel(item) : getTextSearch(item)}
								</div>
							</li>
						))
					)}
				</ul>
			) : null}
			{isTouched && <EzErrorsLabel data={value} validators={validators || []} />}
		</div>
	);
}
