import { createAsyncThunk } from '@reduxjs/toolkit';
import statsService from './stats.service';

export const getSumPerDayAction = createAsyncThunk('stats/sumPerDayCalendar', async (dateReference: Date, thunkAPI) => {
	try {
		return await statsService.getSumPerDay(dateReference);
	} catch (error) {
		return thunkAPI.rejectWithValue('Errore servizio stats/sumPerDayCalendar');
	}
});

export const getClientsOrdersPerMonthAction = createAsyncThunk('stats/getClientsOrdersPerMonth', async (dateReference: Date, thunkAPI) => {
	try {
		return await statsService.getClientsOrdersPerMonth(dateReference);
	} catch (error) {
		return thunkAPI.rejectWithValue('Errore servizio stats/getClientsOrdersPerMonth');
	}
});
