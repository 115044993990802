import moment, {Moment} from 'moment';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {EzSpinner} from '../../../components/core/ez-spinner/EzSpinner';
import {Weekday} from '../../../om/enum/weekday.enum';
import CalendarUtil from '../../../utils/calendar.util';
import useCurrency from '../../../utils/currency.util';
import {CalendarSpdItem} from './calendar-spd-item/CalendarSpdItem';
import {CalendarSpdPagination} from './calendar-spd-pagination/CalendarSpdPagination';
import './calendar-sum-per-day-widget.scss';
import {Skeleton} from '@mui/material';

const prefix = 'calendar-spd';

export const CalendarSumPerDayWidget = ({
  data,
  isLoading,
  onDateChange,
  dateReference,
}: any) => {
  const {t} = useTranslation();
  const {formatCurrency, formatCompactCurrency} = useCurrency();
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [showSkeleton, setShowSkeleton] = useState(false);

  useEffect(() => {
    setIsMobile(handleIsMobile());
    window.addEventListener('resize', () => setIsMobile(handleIsMobile()));
    return () => {
      window.removeEventListener('resize', () => setIsMobile(handleIsMobile()));
    };
  }, []);

  useEffect(() => {
    setShowSkeleton(true);
    if (!isLoading)
      setTimeout(() => {
        setShowSkeleton(false);
      }, 500);
  }, [isLoading]);

  const handleIsMobile = (): boolean =>
    window.matchMedia('(max-width: 768px)').matches;

  const showCalendarTotalHeading = () => (
    <div className='flex flex-row w-full'>
      <div className='flex w-full p-1 py-5 grow justify-center'>
        <div className='_dayweek w-6 uppercase font-semibold text-tprimary text-xs lg:text-sm'>
          {t('WIDGETS.CALENDAR_SPD.SUM_WEEK')}
        </div>
      </div>
    </div>
  );

  const getHeadingDayStringValue = (): string => (isMobile ? 'ddd' : 'dddd');

  const getDayClass = (prefix: string, day: Moment): string => {
    const dayHeadingClass = [prefix];
    if (day.format('e') === '5' || day.format('e') === '6')
      dayHeadingClass.push(`${prefix}--is-weekend`);
    return dayHeadingClass.join(' ');
  };

  const showWeekDayHeading = (day: Moment, index: number) => (
    <div key={index} className='flex w-full p-1 py-5 grow justify-center'>
      <div className={`${getDayClass(`${prefix}__heading-day`, day)} _dayweek`}>
        {day.format(getHeadingDayStringValue())}
      </div>
    </div>
  );

  const getCalendarAmountValue = (value?: number): string =>
    isMobile ? formatCompactCurrency(value || 0) : formatCurrency(value || 0);

  const showWeekSum = (week: any) => (
    <div className='week-sum'>
      <div className='week-sum__value'>
        {showSkeleton ? (
          <Skeleton variant='text' width={'50px'} />
        ) : (
          getCalendarAmountValue(data?.weeks[week]?.sum)
        )}
      </div>
    </div>
  );

  const displayTable = () => {
    const {getCalendarArray, getWeekDays} = CalendarUtil();
    const calendarItems: Array<Map<number, Moment>> =
      getCalendarArray(dateReference);
    return (
      <div id='calendar-spd-table' className='flex flex-col'>
        <div
          className={`${prefix}__days flex flex-row w-full bg-secondary rounded-3xl`}
        >
          {getWeekDays(dateReference).map(showWeekDayHeading)}
          {showCalendarTotalHeading()}
        </div>
        <div className='mt-4'>
          {calendarItems && calendarItems.length
            ? calendarItems.map(
                (calendarItem: Map<number, Moment>, keyWeek: number) => (
                  <div
                    className='flex flex-row w-full items-center'
                    key={keyWeek}
                  >
                    {keyWeek === 0 ? addStartOfMonthPlaceholderDays() : null}
                    {Array.from(calendarItem.keys()).map((weekDay, index) => (
                      <CalendarSpdItem
                        index={index}
                        weekDay={weekDay}
                        calendarItem={calendarItem}
                        isMobile={isMobile}
                        data={data}
                        key={index}
                        isLoading={isLoading}
                      />
                    ))}
                    {keyWeek === calendarItems.length - 1
                      ? addEndOfMonthPlaceholderDays()
                      : null}
                    {showWeekSum(keyWeek)}
                  </div>
                )
              )
            : null}
        </div>
      </div>
    );
  };

  const addStartOfMonthPlaceholderDays = () => {
    let placeholderDaysElements = [];
    const firstMonthWeekDay = moment(dateReference).startOf('month').weekday();
    for (let index = 0; index < firstMonthWeekDay; index++) {
      placeholderDaysElements.push(addPlaceholder('startOfMonth', index));
    }
    return placeholderDaysElements;
  };

  const addPlaceholder = (placeholderKind: string, index: number) => (
    <div
      className={
        'flex w-full p-1 py-5 grow justify-center placeholder --' +
        placeholderKind
      }
      key={placeholderKind + index}
    >
      <div className='w-6' />
      <div />
    </div>
  );

  const addEndOfMonthPlaceholderDays = () => {
    let placeholderDaysElements = [];
    const endMonthWeekDay = moment(dateReference).endOf('month').weekday();
    for (let index = 0; index < Weekday.SUNDAY - endMonthWeekDay; index++) {
      placeholderDaysElements.push(addPlaceholder('endOfMonth', index));
    }
    return placeholderDaysElements;
  };

  const renderTitle = () => moment(dateReference).format('MMMM YYYY');

  return (
    <div id='calendar-spd' className='flex flex-col gap-2'>
      <div className='card rounded-3xl bg-base-100 card-side shadow w-full items-center'>
        <div className='card-body pt-4 pb-0 gap-4'>
          <div className='card-title'>
            <div
              id='calendar-spd-title'
              className='font-normal text-tsecondary h-14 w-full text-2xl flex items-center'
            >
              {renderTitle()}
            </div>
            <CalendarSpdPagination
              dateReference={dateReference}
              onDateChange={onDateChange}
            />
          </div>
          {data ? (
            displayTable()
          ) : isLoading ? (
            <div className='p-2'>
              <EzSpinner size='3rem' />
            </div>
          ) : (
            <div className='d-flex text-center p-5'>
              {t('WIDGETS.CALENDAR_SPD.NO_DATA_FOUND')}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
