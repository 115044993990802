import { useState } from 'react';
import { CommonEzInput } from '../../../om/common-ez-input';
import { EzOption } from '../../../om/ez-option';
import './ez-select.scss';
import { EzErrorsLabel } from '../ez-errors-label/EzErrorsLabel';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

export interface EzSelectProps extends CommonEzInput {
  type?: 'text' | 'date' | 'amount';
  value: any;
  onChange: (e: any) => void;
  options: Array<EzOption>;
  size?: 'small' | 'medium';
}

export function EzSelect({label, options, onChange, value, validators, name, size = 'medium'}: EzSelectProps) {
  const [isTouched, setIsTouched] = useState(false);

  const updateValue = (e: any) => {
    if (!isTouched) setIsTouched(true);
    onChange(e);
  };

  return (
    <div className='ez-select'>
      <FormControl size={size} fullWidth>
        <InputLabel id={name + '-label'}>{label}</InputLabel>
        <Select
          name={name}
          value={value}
          onChange={updateValue}
          className='ez-select__field'
          labelId={name + '-label'}
          label={label}
        >
          {options &&
            options.map((option) => (
              <MenuItem key={option.value} value={option.value.toString()}>
                {option.description}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      {isTouched ? <EzErrorsLabel data={value} validators={validators || []} /> : null}
    </div>
  );
}
