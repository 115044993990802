import moment, {Moment} from 'moment';
import './calendar-spd-item.scss';
import useCurrency from '../../../../utils/currency.util';
import {Skeleton, Typography} from '@mui/material';
import {Fragment, useEffect, useState} from 'react';

interface CalendarSpdItemProps {
  index: number;
  calendarItem: Map<number, any>;
  weekDay: number;
  isMobile: boolean;
  data: any;
  isLoading: boolean;
}

const getCalendarItemClass = (prefix: string, day: Moment): string => {
  const calendarItemClass = [prefix];
  if (day.isSame(moment(), 'day'))
    calendarItemClass.push(`${prefix}--current-day`);
  if (day.format('e') === '5' || day.format('e') === '6')
    calendarItemClass.push(`${prefix}--is-weekend`);
  return calendarItemClass.join(' ');
};

export const CalendarSpdItem = ({
  index,
  calendarItem,
  weekDay,
  isMobile,
  data,
  isLoading,
}: CalendarSpdItemProps) => {
  const [showSkeleton, setShowSkeleton] = useState(false);
  const {formatCurrency, formatCompactCurrency} = useCurrency();
  const getCalendarAmountValue = (value?: number): string =>
    isMobile ? formatCompactCurrency(value || 0) : formatCurrency(value || 0);

  const _getAllOrdersFromData = () =>
    data.weeks.reduce(
      (acc: Array<any>, curr: any) => (acc = [...acc, ...curr.days]),
      []
    );

  const order = _getAllOrdersFromData().find((order: any) =>
    calendarItem.get(weekDay).isSame(moment(order?.date), 'day')
  );

  useEffect(() => {
    setShowSkeleton(true);
    if (!isLoading)
      setTimeout(() => {
        setShowSkeleton(false);
      }, 500);
  }, [isLoading]);

  return (
    <div
      key={index}
      className={getCalendarItemClass(
        'calendar-item',
        calendarItem.get(weekDay)
      )}
    >
      <div className='calendar-item__day'>
        {showSkeleton ? (
          <Skeleton
            className='calendar-item__day'
            variant='text'
            sx={{width: '100%'}}
          />
        ) : (
          calendarItem.get(weekDay).format('D')
        )}
      </div>
      <div className='calendar-item__value'>
        {showSkeleton ? (
          <Fragment>
            <Skeleton className='calendar-item__value' variant='text' />
          </Fragment>
        ) : (
          getCalendarAmountValue(order?.value)
        )}
      </div>
    </div>
  );
};
