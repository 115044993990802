import { TableCell } from '@mui/material';

export function EzTableHeading({column, index, customClasses}: any) {
  const styles = {
    ...(column.stickyLeftWidth && {left: column.stickyLeftWidth}),
  };

  return (
    <TableCell
      align={column.order}
      className={`ez-table__heading ${column.sticky ? 'ez-table__heading--sticky' : null} ${customClasses}`}
      style={styles}
    >
      {column.label}
    </TableCell>
  );
}
