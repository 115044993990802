import { createSlice } from '@reduxjs/toolkit';
import { AuthState } from '../../om/features/auth.state';
import { getProfileBuilders, loginBuilders } from './auth.builders';

const initialState: AuthState = {
	user: null,
	isError: false,
	isSuccess: false,
	isLoading: false,
	isLogoutError: false,
	isLogoutSuccess: false
};

export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		reset: (state: AuthState) => {
			state.isLoading = false;
			state.isError = false;
			state.isSuccess = false;
			state.isLoading = false;
			state.isLogoutError = false;
			state.isLogoutSuccess = false;
		}
	},
	extraReducers: (builder) => {
		loginBuilders(builder);
		getProfileBuilders(builder);
	}
});

export default authSlice.reducer;
