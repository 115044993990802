import { useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { Footer } from '../../../components/footer/Footer';
import { getProfileAction } from '../../../features/auth/auth.actions';
import {
	useAppDispatch,
	useAppSelector
} from '../../../hooks/redux-toolkit.hook';
import { LoadingView } from '../../loading/LoadingView';
import './public-routes.scss';

export const PublicRoute = () => {
	const dispatch = useAppDispatch();
	const location = useLocation();
	const { user } = useAppSelector((state: any) => state.auth);
	const [isChecking, setIsChecking] = useState(true);

	useEffect(() => {
		const checkIfIsLoggedIn = async () => {
			setIsChecking(true);
			await dispatch(getProfileAction());
			setIsChecking(false);
		};

		checkIfIsLoggedIn();
	}, [dispatch]);

	if (isChecking) return <LoadingView />;
	if (user)
		return (
			<Navigate
				to={{
					pathname: '/dashboard'
				}}
			/>
		);
	return (
		<div className='public-routes'>
			<Outlet />
			<Footer />
		</div>
	);
};
