import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaChartLine } from 'react-icons/fa';
import { MdEuroSymbol, MdOutlineCalendarToday, MdOutlineDateRange } from 'react-icons/md';
import { RootState } from '../../app/store';
import { EzStatsCard } from '../../components/ez-stats-card/EzStatsCard';
import { getAllAction, getCountersAction } from '../../features/orders/orders.actions';
import { getSumPerDayAction } from '../../features/stats/stats.actions';
import { useAppDispatch, useAppSelector } from '../../hooks/redux-toolkit.hook';
import useCurrency from '../../utils/currency.util';
import { CalendarSumPerDayWidget } from './calendar-sum-per-day/CalendarSumPerDayWidget';
import './dashboard-view.scss';

export const DashboardView = () => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const { sumPerDay: sumPerDayData, isSumPerDayLoading } = useAppSelector((state: any) => state.stats);
	const { counters: countersData } = useAppSelector((state: RootState) => state.orders);
	const { formatCurrency } = useCurrency();
	const [calendarDateRef, setCalendarDateRef] = useState(new Date());

	useEffect(() => {
		dispatch(getAllAction());
		dispatch(getCountersAction());
	}, []);

	const year: string = moment().format('YYYY').toString();
	const month: string = moment().format('MMMM').toString();

	const onDateChange = async (arrow: 'next' | 'prev') => {
		setCalendarDateRef((actualDate: Date) =>
			moment(actualDate)[arrow === 'next' ? 'add' : 'subtract'](1, 'months').toDate()
		);
	};

	useEffect(() => {
		dispatch(getSumPerDayAction(calendarDateRef));
	}, [calendarDateRef]);

	return (
		<div className='container flex flex-col gap-9 mx-auto px-6 xl:px-8 mt-8 mb-5'>
			<div className='flex flex-wrap gap-6 xl:gap-5' id='stats'>
				<EzStatsCard
					description={t('DASHBOARD.STATS_AMOUNT.YEAR', { year })}
					value={formatCurrency(countersData?.totYear || 0)}
					icon={<MdEuroSymbol />}
				/>
				<EzStatsCard
					description={t('DASHBOARD.STATS_AMOUNT.MONTH', { month })}
					value={formatCurrency(countersData?.totMonth || 0)}
					icon={<MdOutlineCalendarToday />}
				/>
				<EzStatsCard
					description={t('DASHBOARD.STATS_AMOUNT.LAST_WEEK')}
					value={formatCurrency(countersData?.totCurrWeek || 0)}
					icon={<MdOutlineDateRange />}
				/>
			</div>
			<div className='widget-container'>
				<CalendarSumPerDayWidget
					data={sumPerDayData}
					isLoading={isSumPerDayLoading}
					onDateChange={onDateChange}
					dateReference={calendarDateRef}
				/>
			</div>
		</div>
	);
};
