import {createSlice} from '@reduxjs/toolkit';
import {StatsState} from '../../om/features/stats.state';
import {getClientsOrdersPerMonthBuilders, getSumPerDayBuilders} from './stats.builders';

const initialState: StatsState = {
  sumPerDay: null,
  isSumPerDayLoading: false,
  clientsOrdersPerMonth: null,
  isClientsOrdersPerMonthLoading: false,
};

export const statsSlice = createSlice({
  name: 'stats',
  initialState,
  reducers: {
    reset: (state: StatsState) => {
      state.sumPerDay = null;
      state.isSumPerDayLoading = false;
      state.clientsOrdersPerMonth = null;
      state.isClientsOrdersPerMonthLoading = false;
    },
  },
  extraReducers: (builder) => {
    getSumPerDayBuilders(builder);
		getClientsOrdersPerMonthBuilders(builder);
  },
});

export default statsSlice.reducer;
