import { StatsState } from '../../om/features/stats.state';
import { getClientsOrdersPerMonthAction, getSumPerDayAction } from './stats.actions';

export const getSumPerDayBuilders = (builder: any): any => {
	builder
		.addCase(getSumPerDayAction.pending, (state: StatsState, action: any) => {
			state.isSumPerDayLoading = true;
		})
		.addCase(getSumPerDayAction.rejected, (state: StatsState, action: any) => {
			state.isSumPerDayLoading = false;
		})
		.addCase(getSumPerDayAction.fulfilled, (state: StatsState, action: any) => {
			state.sumPerDay = action.payload;
			state.isSumPerDayLoading = false;
		});
	return builder;
};

export const getClientsOrdersPerMonthBuilders = (builder: any): any => {
	builder
		.addCase(getClientsOrdersPerMonthAction.pending, (state: StatsState, action: any) => {
			state.isClientsOrdersPerMonthLoading = true;
		})
		.addCase(getClientsOrdersPerMonthAction.rejected, (state: StatsState, action: any) => {
			state.isClientsOrdersPerMonthLoading = false;
		})
		.addCase(getClientsOrdersPerMonthAction.fulfilled, (state: StatsState, action: any) => {
			state.clientsOrdersPerMonth = action.payload;
			state.isClientsOrdersPerMonthLoading = false;
		});
	return builder;
};
