import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../hooks/redux-toolkit.hook';
import './EzToaster.scss';
import { FaInfoCircle, FaRegCheckCircle, FaRegTimesCircle, FaTimesCircle } from 'react-icons/fa';
import { ToasterKind } from '../../../om/enum/toaster-kind.enum';
import { Toaster } from '../../../om/toaster';

export default function EzToaster() {
	const { toasters } = useAppSelector((state) => state.toaster);
	const { t } = useTranslation();

	const getIconFromTpasterKind = (kind: ToasterKind) => (kind === ToasterKind.ERROR ? <FaRegTimesCircle /> : kind === ToasterKind.SUCCESS ? <FaRegCheckCircle /> : <FaInfoCircle />);

	return (
		<div id="toast-container" className="toast toast-top toast-end">
			{toasters.map((toaster: Toaster, index: number) => (
				<div key={index} className={'alert alert-' + toaster.kind}>
					<div>
						{getIconFromTpasterKind(toaster.kind)}
						<span>{t(toaster.message)}</span>
					</div>
				</div>
			))}
			<div className="alert alert-error" style={{ display: 'none' }} />
			<div className="alert alert-success" style={{ display: 'none' }} />
		</div>
	);
}
