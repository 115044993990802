import { t } from 'i18next';
import { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import { HiArrowLeft } from 'react-icons/hi';
import { EzCenterContent } from '../../components/core/ez-center-content/EzCenterContent';

export const RegisterView = ({ children }: PropsWithChildren) => (
	<EzCenterContent>
		<div className="card flex-shrink-0 w-full max-w-md shadow-xl bg-base-100">
			<div className="card-body">
				<h1 className="text-primary text-center text-4xl font-bold mb-5">{t('PAGES.REGISTER_TITLE')}</h1>

				<input type="text" placeholder="email" className="input input-bordered" />
				<input type="text" placeholder="username" className="input input-bordered" />
				<input type="password" placeholder="password" className="input input-bordered" />
				<input type="password" placeholder="conferma password" className="input input-bordered" />

				<label className="cursor-pointer label justify-start">
					<input type="checkbox" id="confirmConditions" className="checkbox" />
					<span className="label-text pl-2">{t('COMMON.ACCEPT_TERMS_QUESTION')}</span>
				</label>

				<div id="register-buttons" className="flex justify-between">
					<Link to="/" className="btn btn-ghost">
						<HiArrowLeft size={20} />&nbsp;&nbsp;{t('COMMON.CANCEL')}
					</Link>
					<Link to="/" className="btn btn-primary">
						{t('COMMON.CONFIRM')}
					</Link>
				</div>
			</div>
		</div>
	</EzCenterContent>
);
