import { PropsWithChildren, useEffect } from "react";

export interface EzPageInterface extends PropsWithChildren {
    title:string;
}

export default function EzPage({title, children}: EzPageInterface ) {
  useEffect(() => {
    document.title = title || "";
  }, [title]);
  return <>{children}</>;
};