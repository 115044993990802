import { t } from 'i18next';
import { Fragment, useEffect, useState } from 'react';
import { EzTableColumnType } from '../../om/enum/ez-table-column-type.enum';
import { CuClientModal } from './new-client-modal/CuClientModal';

import { Add } from '@mui/icons-material';
import { Button, OutlinedInput, Typography } from '@mui/material';
import { EzCard } from '../../components/core/ez-card/EzCard';
import { EzHeading } from '../../components/core/ez-heading/EzHeading';
import { EzTable } from '../../components/core/ez-table/EzTable';
import { EzToolbar } from '../../components/core/ez-toolbar/EzToolbar';
import { getAllAction } from '../../features/clients/clients.actions';
import { useAppDispatch, useAppSelector } from '../../hooks/redux-toolkit.hook';
import { ClientDTO } from '../../om/dto/clients/client-dto';
import { EzTableAction } from '../../om/ez-table-action';
import { EzTableColumn } from '../../om/ez-table-column';
import { DeleteClientModal } from './delete-client-modal/DeleteClientModal';
import { tableUtil } from '../../utils/table.util';

export const ClientListView = () => {
	const dispatch = useAppDispatch();
	const {filterSearchData} = tableUtil();
	const { clients } = useAppSelector((state: any) => state.clients);
	const [ clientToDelete, setClientToDelete ] = useState<any>(null);
	const [ clientToEdit, setClientToEdit ] = useState<any>(null);
	const [ clientsListFilter, setClientsListFilter ] = useState<any>(null);

	const [ isCUClientModalOpen, setIsCuClientModalOpen ] = useState(false);
	const [ isDeleteClientModalOpen, setIsDeleteClientModalOpen ] = useState(false);

	useEffect(() => {
		updateClientsList();
	}, []);

	const updateClientsList = async () => {
		setClientToEdit(null);
		await dispatch(getAllAction());
	};

	const closeCUClientModalCallback = () => {
		setIsCuClientModalOpen(false);
		setClientToEdit(null);
	};

	const openCUClientModal = () => {
		setIsCuClientModalOpen(true);
	};

	const closeDeleteClientModal = () => {
		setIsDeleteClientModalOpen(false);
		setTimeout(() => {
			setClientToDelete(null);
		}, 500);
	};

	const searchClients = (valueToSearch?: string) => {
		setClientsListFilter({...clientsListFilter, search: valueToSearch})
	};
	
	const getClientsData = () => {
		let clientsToDisplay: ClientDTO[] = clients;
		if(clientsListFilter?.search && '' !== clientsListFilter?.search )
		clientsToDisplay = filterSearchData(clientsToDisplay, clientsListFilter);
		else clientsToDisplay = clients;
		return clientsToDisplay;
	};

	const tableColumnSettings: Array<EzTableColumn> = [
		{
			id: 'code',
			label: 'Codice',
			columnType: EzTableColumnType.TEXT
		},
		{
			id: 'company',
			label: 'Rag. Sociale',
			columnType: EzTableColumnType.TEXT
		},
		{
			id: 'city',
			label: 'Città',
			columnType: EzTableColumnType.TEXT
		}
	];

	const tableActions: Array<EzTableAction> = [
		{
			desription: 'Modifica',
			className: 'btn btn-ghost text-primary',
			onClick: (e: any) => {
				openCUClientModal();
				setClientToEdit(e);
			}
		},
		{
			desription: 'Elimina',
			className: 'btn btn-ghost text-primary',
			onClick: (e: any) => {
				setClientToDelete(e);
				setIsDeleteClientModalOpen(true);
			}
		}
	];

	return (
		<Fragment>
			<div className="container mx-auto px-6 xl:px-8 mb-5">
				<div className="mt-5">
					<EzCard>
						<EzHeading
							title={t('CLIENTS_LIST.PAGE_TITLE')}
							contentEnd={
								<Button onClick={openCUClientModal} startIcon={<Add />} variant="contained" color="primary" type="submit" size="large">
									{t('CLIENTS_LIST.NEW_CLIENT')}
								</Button>
							}
						/>
						<EzToolbar onSearch={searchClients} />
						<EzTable id="lista-clienti" columns={tableColumnSettings} data={getClientsData()} actions={tableActions} />
					</EzCard>
				</div>
			</div>

			<CuClientModal isOpen={isCUClientModalOpen} modalId="cu-client-modal" callback={updateClientsList} clientToEdit={clientToEdit} closeCallback={closeCUClientModalCallback} />
			<DeleteClientModal isOpen={isDeleteClientModalOpen} modalId="delete-client-modal" clientToDelete={clientToDelete} callback={updateClientsList} closeCallback={closeDeleteClientModal} />
		</Fragment>
	);
};
