import { apiManagerUtil } from '../../utils/api-manager.util';

export default function crudService(path: string) {
  const apiManager = apiManagerUtil();

  const getAll = async (filters: any) => {
    const response = await apiManager.get(path, {params: filters});
    return response.data;
  };

  const create = async (input: any) => {
    const response = await apiManager.post(path, input);
    return response.data;
  };

  const deleteAPI = async (input: any) => {
    const response = await apiManager.deleteAPI(`${path}/${input}`);
    return response.data;
  };
  const update = async (id: number, body?: any) => {
    const response = await apiManager.put(`${path}/${id}`, body);
    return response.data;
  };

  const search = async (body?: any) => {
    const response = await apiManager.post(path + '/search', body);
    return response.data;
  };

  const getOne = async (id?: number) => {
    const response = await apiManager.get(path + '/' + id);
    return response.data;
  };

  return { getAll, create, update, deleteAPI, search, getOne };
}
