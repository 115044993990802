import { t } from 'i18next';
import { EzChangeTheme } from '../ez-change-theme/EzChangeTheme';
import './footer.scss';

export const Footer = () => (
	<div className="ew-footer w-full flex-shrink-0">
		<footer className="footer items-center bg-neutral text-neutral-content">
			<div className="flex flex-col w-full lg:flex-row p-4 justify-between items-center text-center">
				<span />
				<div>{t('UI.FOOTER_DESCRIPTION')}</div>
				<div>{/* {<EzChangeTheme />} */}</div>
			</div>
		</footer>
	</div>
);
