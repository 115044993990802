import { AppBar, Box, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Toolbar } from '@mui/material';
import { useState } from 'react';
import { MdOutlineMenu } from 'react-icons/md';
import { NavLink } from 'react-router-dom';
import { MenuItem } from '../../om/menu-item';
import './mobilenavbar.scss';
import { EzMenu } from '../core/ez-menu/EzMenu';

const prefix = 'ez-mobile-navbar';

export interface MobileNavbarInterface {
	menu: Array<MenuItem>;
}

export const MobileNavbar = ({ menu }: MobileNavbarInterface) => {
	const [ isOpen, setIsOpen ] = useState(false);

	const toggleDrawer = (event: any) => {
		if (event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
			return;
		}
		setIsOpen(!isOpen);
	};

	const renderDrawerContent = () => (
		<Box sx={{ width: 250 }} role="presentation" onClick={(e) => toggleDrawer(e)} onKeyDown={(e) => toggleDrawer(e)} className="px-4">
			<div className={`${prefix}__head-divider mb-4`}>
				<Divider className="pb-12" />
			</div>
			<EzMenu menu={menu} />
		</Box>
	);

	return (
		<Box sx={{ flexGrow: 1 }}>
			<AppBar position="static">
				<Toolbar className="bg-base-100">
					<IconButton onClick={(e) => toggleDrawer(e)} size="large" edge="start" color="primary" aria-label="menu" sx={{ mr: 2 }}>
						<MdOutlineMenu />
					</IconButton>
					<div className={`${prefix}__brand navbar text-primary flex justify-center`}>
						<a className="btn btn-ghost normal-case text-2xl">ezWork</a>
					</div>
				</Toolbar>
			</AppBar>
			<Drawer anchor={'left'} open={isOpen} onClose={(e) => toggleDrawer(e)}>
				{renderDrawerContent()}
			</Drawer>
		</Box>
	);
};
