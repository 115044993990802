import { Add } from '@mui/icons-material';
import { Button } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EzCard } from '../../components/core/ez-card/EzCard';
import { EzHeading } from '../../components/core/ez-heading/EzHeading';
import { EzTable } from '../../components/core/ez-table/EzTable';
import { EzToolbar } from '../../components/core/ez-toolbar/EzToolbar';
import { EzFilterChips } from '../../components/ez-filter-chips/EzFilterChips';
import { EzFilterPanel } from '../../components/ez-filter-panel/EzFilterPanel';
import { getAllAction as getAllClients } from '../../features/clients/clients.actions';
import { getAllAction } from '../../features/orders/orders.actions';
import { useAppDispatch, useAppSelector } from '../../hooks/redux-toolkit.hook';
import { OrderDTO, OrdersFilter } from '../../om/dto/orders/order-dto';
import { inputUtil } from '../../utils/input.util';
import { OrdersListViewFiltersManager } from './OrdersListViewFiltersManager';
import { OrdersListViewTable } from './OrdersListViewTable';
import { DeleteOrderModal } from './delete-order-modal/DeleteOrderModal';
import { OrderModal } from './order-modal/OrderModal';
import './orders-list.scss';

const prefix = 'orders-list';

export function OrdersListView() {
	const { t } = useTranslation();
	const [orderToDelete, setOrderToDelete] = useState<OrderDTO | null>(null);
	const [orderToEdit, setOrderToEdit] = useState<any>(null);
	const [filterPanelAnchorElement, setFilterPanelAnchorElement] =
		useState<any>(false);
	const [isCUOrderModalOpen, setIsCUOrderModalOpen] = useState<boolean>(false);
	const [isDeleteOrderModalOpen, setIsDeleteOrderModalOpen] =
		useState<boolean>(false);
	const [ordersFiltersList, setOrdersFiltersList] = useState<any>(null);
	const [filtersToAdd, setFiltersToAdd] = useState<any>(null);
	const { getUpdatedValue, getCustomUpdatedValue } = inputUtil();
	const dispatch = useAppDispatch();
	const { orders, isOrderDetailsLoading } = useAppSelector(
		(state: any) => state.orders
	);
	const { clients } = useAppSelector((state: any) => state.clients);
	const {
		getOrderPanelFilters,
		filterOrdersFromResearch,
		filterOrderSingleField
	} = OrdersListViewFiltersManager();
	const { tableColumnSettings, tableActions } = OrdersListViewTable();

	const updateOrdersList = async () => {
		await dispatch(getAllAction(getOrdersBackendFilter()));
	};

	useEffect(() => {
		setInitialData();
		updateOrdersList();
		dispatch(getAllClients());
	}, []);

	useEffect(() => {
		updateOrdersList();
	}, [ordersFiltersList]);

	const setInitialData = () => {
		setOrdersFiltersList((prevState: any) => ({
			...prevState,
			dateFrom: moment(`${moment().format('YYYY')}-01-01`),
			dateTo: moment(`${moment().format('YYYY')}-12-31`)
		}));
	};

	const getOrdersBackendFilter = (): OrdersFilter => ({
		dateFrom: ordersFiltersList?.dateFrom?.format('YYYY/MM/DD'),
		dateTo: ordersFiltersList?.dateTo?.format('YYYY/MM/DD')
	});

	const onFilterChange = (e: any) => {
		setFiltersToAdd((prevState: any) => getUpdatedValue(e, prevState));
	};

	const onDatePickerChange = (e: any, name: string) => {
		setOrdersFiltersList((prevState: any) =>
			getCustomUpdatedValue(e, prevState, name)
		);
	};

	const onClientFilterChange = (e: any) => {
		setFiltersToAdd((prevState: any) =>
			getCustomUpdatedValue(e.target.innerText, prevState, 'clientDescription')
		);
	};

	const applyFilters = () => {
		setOrdersFiltersList((prevState: any) => ({
			...prevState,
			...filtersToAdd
		}));
		setFiltersToAdd(null);
		closeFilterPanel();
	};

	const onCUOrderModalClose = () => {
		setIsCUOrderModalOpen(false);
		setOrderToEdit(null);
	};

	const onDeleteOrderClick = (item: OrderDTO) => {
		setOrderToDelete(item);
		setIsDeleteOrderModalOpen(true);
	};

	const onDeleteOrder = () => {
		setOrderToDelete(null);
		setIsDeleteOrderModalOpen(false);
		updateOrdersList();
	};

	const onEditOrderClick = (item: OrderDTO) => {
		setOrderToEdit(item);
		setIsCUOrderModalOpen(true);
	};

	const closeFilterPanel = () => setFilterPanelAnchorElement(null);

	const renderFilterPanel = () => (
		<EzFilterPanel
			onClose={closeFilterPanel}
			anchorElement={filterPanelAnchorElement}
			filters={getOrderPanelFilters(
				filtersToAdd,
				clients,
				(e) => onFilterChange(e),
				(e) => onClientFilterChange(e)
			)}
			applyFilters={applyFilters}
		/>
	);

	const renderDatePickers = () => (
		<div className={`${prefix}__date-range flex items-center gap-2 w-full`}>
			<DatePicker
				slotProps={{ textField: { size: 'small', name: 'dateFrom' } }}
				value={ordersFiltersList?.dateFrom}
				className='max-w-[12rem]'
				onChange={(e) => onDatePickerChange(e, 'dateFrom')}
			/>
			<span>-</span>
			<DatePicker
				slotProps={{ textField: { size: 'small', name: 'dateTo' } }}
				value={ordersFiltersList?.dateTo}
				className='max-w-[12rem]'
				onChange={(e) => onDatePickerChange(e, 'dateTo')}
			/>
		</div>
	);

	const mapToActualFields = (fields: OrderDTO) => ({
		...fields,
		clientDescription: fields.client?.company
	});

	const getOrdersData = (): OrderDTO[] => {
		let ordersToDisplay: OrderDTO[] = orders.map((order: OrderDTO) =>
			mapToActualFields(order)
		);
		ordersToDisplay = ordersToDisplay.filter(
			(order: OrderDTO) =>
				filterOrdersFromResearch(ordersFiltersList, order) &&
				filterOrderSingleField(ordersFiltersList, order)
		);
		return ordersToDisplay;
	};

	const onFilterChipDelete = (filter: string) => {
		setOrdersFiltersList((prevState: any) => ({
			...prevState,
			[filter]: ''
		}));
		setFiltersToAdd(null);
	};

	const renderFiltersChip = () => (
		<EzFilterChips
			page='orders'
			filtersList={ordersFiltersList}
			filtersToIgnore={['dateFrom', 'dateTo']}
			onChipDelete={(filter) => onFilterChipDelete(filter)}
		/>
	);

	const renderTableHeader = () => (
		<div className='orders-list__table-head'>
			<EzToolbar
				onFilterButtonClick={(e) =>
					setFilterPanelAnchorElement(e.currentTarget)
				}
				onSearch={(valueToSearch) =>
					setOrdersFiltersList({
						...ordersFiltersList,
						search: valueToSearch
					})
				}
				filterContent={renderFilterPanel()}
				contentEnd={renderDatePickers()}
				filterChips={renderFiltersChip()}
			/>
		</div>
	);

	return (
		<Fragment>
			<div className='orders-list mt-5'>
				<EzCard>
					<EzHeading
						title={t('ORDERS.PAGE_TITLE')}
						contentEnd={
							<Button
								onClick={() => setIsCUOrderModalOpen(true)}
								startIcon={<Add />}
								variant='contained'
								color='primary'
								size='large'
							>
								{t('ORDERS.NEW_LABEL')}
							</Button>
						}
					/>
					{renderTableHeader()}
					<div className='orders-list____table-container'>
						<EzTable
							id='orders-list'
							columns={tableColumnSettings}
							data={getOrdersData()}
							actions={tableActions(onEditOrderClick, onDeleteOrderClick)}
						/>
					</div>
				</EzCard>
			</div>
			<OrderModal
				isOpen={isCUOrderModalOpen}
				orderToEdit={orderToEdit}
				clients={clients}
				isLoading={isOrderDetailsLoading}
				onClose={onCUOrderModalClose}
			/>
			<DeleteOrderModal
				isOpen={isDeleteOrderModalOpen}
				orderToDelete={orderToDelete}
				modalId='delete-order-modal'
				callback={onDeleteOrder}
				onClose={() => setIsDeleteOrderModalOpen(false)}
			/>
		</Fragment>
	);
}
