import axios, { AxiosRequestConfig } from 'axios';
import apiList from '../assets/api/apilist.json';

export const apiManagerUtil = () => {
  const apiConfig: any = {
    LOCAL: 'http://localhost:3001/',
    TEST: 'http://test-api.ezwork.it',
    PROD: 'https://api.ezwork.it',
  };
  const MOCKOON_URL = 'http://localhost:3005/';
  const REFRESH_TOKEN_ENDPOINT = 'auth/refresh';

  const apiManagerConfig = {
    baseURL: apiConfig[process.env.REACT_APP_ENVIRONMENT || 'LOCAL'],
    withCredentials: true,
  };

  console.log('test', process.env.REACT_APP_ENVIRONMENT, process.env);

  const NSApiManager = axios.create(apiManagerConfig);

  const _tryToRefreshToken = (originalRequest: any) => {
    return NSApiManager.get(REFRESH_TOKEN_ENDPOINT).then(() =>
      NSApiManager(originalRequest)
    );
  };

  const _setRetryToTrueAndRefreshToken = (originalRequest: any) => {
    originalRequest._retry = true;
    return _tryToRefreshToken(originalRequest);
  };

  /**
   * Se la response è 401 Unauthorized
   * Se si tratta della request fatta al refreshToken, respingi la promise
   * Altrimenti, prova a richiedere il token tramite il servizio di refresh
   * @param error
   * @returns
   */
  const _useRefreshTokenIf401 = (error: any) => {
    const originalRequest = error.config;
    const isRes401 = error.response.status === 401;
    if (isRes401 && originalRequest.url === REFRESH_TOKEN_ENDPOINT)
      return Promise.reject(error);
    if (isRes401 && !originalRequest._retry)
      return _setRetryToTrueAndRefreshToken(originalRequest);
  };

  NSApiManager.interceptors.response.use(
    (response) => response,
    _useRefreshTokenIf401
  );

  const _getALlServicesFromList = () =>
    apiList.apis.reduce(
      (acc: Array<any>, curr) => [...acc, ...curr.services],
      []
    );

  const haveToBeMocked = (endpoint: string): boolean => {
    return _getALlServicesFromList().some(
      (service) => service.endpoint === endpoint && service.mockoon
    );
  };

  const getApiManagerForEndpoint = (endpoint: string) => {
    if (haveToBeMocked(endpoint)) return axios.create({ baseURL: MOCKOON_URL });
    return NSApiManager;
  };

  /**
   * Effettua una chiamata tramite Axios su metodo GET
   *
   * @param {*} endpoint
   * @param {*} callback
   */
  const get = (endpoint: string, params = {}): any => {
    const apiManager = getApiManagerForEndpoint(endpoint);
    return apiManager.get(endpoint, {params});
  };

  /**
   * Effettua una chiamata tramite Axios su metodo POST
   *
   * @param {*} endpoint
   * @param {*} callback
   */
  const post = (endpoint: string, body: any = {}): any => {
    const apiManager = getApiManagerForEndpoint(endpoint);
    return apiManager.post(endpoint, body);
  };

  /**
   * Effettua una chiamata tramite Axios su metodo PUT
   *
   * @param {*} endpoint
   * @param {*} callback
   */
  const put = (
    endpoint: string,
    body: any,
    callback = () => {},
    errorCallback = () => {}
  ): any => {
    const apiManager = getApiManagerForEndpoint(endpoint);
    return apiManager.put(endpoint, body);
  };

  /**
   * Effettua una chiamata tramite Axios su metodo DELETE
   *
   * @param {*} endpoint
   * @param {*} callback
   */
  const deleteAPI: any = (
    endpoint: string,
    callback = () => {},
    errorCallback = () => {}
  ): any => {
    const apiManager = getApiManagerForEndpoint(endpoint);
    return apiManager.delete(endpoint);
  };

  return { NSApiManager, get, post, put, deleteAPI };
};
