import { Button, Popover } from '@mui/material';

export interface PanelFilterInterface {
  label: string;
  input: any;
}

export interface EzFilterPanelInterface {
  id?: string;
  onClose?: () => void;
  anchorElement: any;
  filters: PanelFilterInterface[];
  applyFilters: () => void;
}

const prefix = 'ez-filter-panel';

export function EzFilterPanel({ id, onClose, anchorElement, filters, applyFilters }: EzFilterPanelInterface) {
  const isOpen = Boolean(anchorElement);

  const renderFilters = () =>
    filters.map((filter) => (
      <div className="filter-code flex justify-between gap-4">
        <div className="font-bold text-xs flex items-center basis-16">{filter.label}</div>
        {filter.input}
      </div>
    ));

  const renderFooter = () => (
    <div className="filter-footer flex justify-end">
      <Button size="small" variant="contained" type="submit" onClick={applyFilters}>
        Applica
      </Button>
    </div>
  );

  const closeFilterPanel = () => {
    onClose && onClose();
  };

  return (
    <Popover
      id={id}
      open={isOpen}
      onClose={closeFilterPanel}
      anchorEl={anchorElement}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
    >
      <div className={`${prefix} p-4 flex flex-col gap-4`}>
        <div className={`${prefix}__filter-body flex flex-col gap-2`}>{renderFilters()}</div>
        {renderFooter()}
      </div>
    </Popover>
  );
}
