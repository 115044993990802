import { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
	createAction,
	updateAction
} from '../../../features/clients/clients.actions';
import { useAppSelector } from '../../../hooks/redux-toolkit.hook';
import { useToast } from '../../../hooks/toaster.hook';
import { EzModal } from '../../../components/core/ez-modal/EzModal';
import { Button, OutlinedInput, TextField } from '@mui/material';
import { BaseFormModal } from '../../../om';
import { ClientDTO } from '../../../om/dto/clients/client-dto';

export interface ClientFormModalProps extends BaseFormModal<ClientDTO> {}

export const CuClientModal = ({
	modalId,
	clientToEdit,
	callback,
	closeCallback,
	isOpen
}: any) => {
	const toaster = useToast();

	const { clients } = useAppSelector((state: any) => state.clients);
	const dispatch = useDispatch();
	const initialClientFormData = {
		code: '',
		city: '',
		company: ''
	};
	const [ formNewClientData, setFormNewClientData ] = useState<any>(
		initialClientFormData
	);

	useEffect(
		() => {
			if (clientToEdit) {
				setFormNewClientData({ ...clientToEdit });
			} else setFormNewClientData(initialClientFormData);
		},
		[ clientToEdit ]
	);

	useEffect(() => {
		toaster.reset();
	}, []);

	const onChange = (e: any) => {
		setFormNewClientData((prevState: any) => ({
			...prevState,
			[e.target.name]: e.target.value
		}));
	};

	const clearFormData = () => {
		setFormNewClientData({
			code: '',
			city: '',
			company: ''
		});
	};

	const cuClientAction = async () =>
		await dispatch(
			clientToEdit
				? updateAction({ id: clientToEdit.id, body: formNewClientData })
				: createAction(formNewClientData)
		);

	const submitNewClient = async (e: any) => {
		e.preventDefault();
		const result = await cuClientAction();
		console.log(result);
		if (result.error) toaster.error('ERRORS.AUTH');
		else {
			successCUClient();
			callback();
			clearFormData();
			closeCallback();
		}
		console.log('Input cliente: ', formNewClientData);
	};

	const successCUClient = () => {
		if (clientToEdit)
			toaster.success(
				`Cliente ${formNewClientData.company}  aggiornato con successo`
			);
		else
			toaster.success(
				`Cliente ${formNewClientData.company}  aggiunto con successo`
			);
	};

	const isNewClientFormValid = (): boolean | undefined => {
		const { code, city, company } = formNewClientData;
		return !!code && !!city && !!company;
	};

	const cuClientModalAction = (
		<Button
			variant="contained"
			type="submit"
			form="cu-client"
			disabled={!isNewClientFormValid()}
		>
			CONFERMA
		</Button>
	);

	const onCloseModal = () => {
		clearFormData();
		closeCallback();
	};

	return (
		<Fragment>
			<EzModal
				isOpen={isOpen}
				id={modalId}
				title="Nuovo Cliente"
				actions={cuClientModalAction}
				closeCallback={onCloseModal}
			>
				<form name="cu-client" id="cu-client" onSubmit={submitNewClient}>
					<div className="flex flex-col gap-6 mt-2 w-80">
						<TextField
							name="code"
							label="Codice"
							onChange={onChange}
							value={formNewClientData.code}
							variant="outlined"
						/>
						<TextField
							name="city"
							label="Città"
							onChange={onChange}
							value={formNewClientData.city}
							variant="outlined"
						/>
						<TextField
							name="company"
							label="Ragione sociale"
							onChange={onChange}
							value={formNewClientData.company}
							variant="outlined"
						/>
					</div>
				</form>
			</EzModal>
		</Fragment>
	);
};
