import React from 'react';
import { StatsCardItem } from '../../om/stats-card-item';

const prefix = 'ez-stats-card';

export const EzStatsCard = (props: StatsCardItem) => {
	const icon = React.cloneElement(props.icon, { className: 'fill-primary' });
	return (
		<div className={`${prefix} flex-1 card rounded-3xl bg-base-100 card-side shadow p-4 grow items-center`}>
			<figure className="w-12 h-12 md:w-14 md:h-14 xl:w-20 xl:h-20 bg-base-200 border-0 rounded-xl p-2 xl:p-5">{icon}</figure>
			<div className="card-body text-end p-0">
				<h2 className="text-sm text-tsecondary">{props.description}</h2>
				<h1 className="md:text-xl text-xl text-tprimary font-bold">{props.value}</h1>
			</div>
		</div>
	);
};
