import { PropsWithChildren, useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { Footer } from '../../../components/footer/Footer';
import { MobileNavbar } from '../../../components/mobilenavbar/MobileNavbar';
import { SideNav } from '../../../components/sidenav/SideNav';
import { getProfileAction } from '../../../features/auth/auth.actions';
import {
	useAppDispatch,
	useAppSelector
} from '../../../hooks/redux-toolkit.hook';
import { LoadingView } from '../../loading/LoadingView';
import { NAVBAR_MENU } from './NavbarMenuConst';
import './auth-route.scss';

export const AuthRoute = ({ children }: PropsWithChildren) => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const { user, isLoading, isError } = useAppSelector(
		(state: any) => state.auth
	);
	const [isMobile, setIsMobile] = useState<boolean>(false);

	useEffect(() => {
		const checkProfile = async () => {
			if (!user) {
				await dispatch(getProfileAction());
			}
			if (isError) return navigate('/login');
		};
		checkProfile();
	}, [user, isError]);

	useEffect(() => {
		setIsMobile(handleIsMobile());
		window.addEventListener('resize', () => setIsMobile(handleIsMobile()));
		return () => {
			window.removeEventListener('resize', () => setIsMobile(handleIsMobile()));
		};
	}, []);

	const handleIsMobile = (): boolean =>
		window.matchMedia('(max-width: 768px)').matches;

	if (isLoading) return <LoadingView />;

	return user && !isLoading ? (
		<div className='xl:h-screen md:h-screen min-h-screen flex flex-col'>
			<div className='flex flex-shrink-0 basis-auto'>
				{!isMobile ? <SideNav menu={NAVBAR_MENU} /> : null}
				<div className='page flex flex-1 justify-between flex-col h-full'>
					{isMobile ? <MobileNavbar menu={NAVBAR_MENU} /> : null}
					<Outlet />
					<Footer />
				</div>
			</div>
		</div>
	) : null;
};
