import { AccountCircleOutlined, Google, LockOutlined, Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton, InputAdornment, OutlinedInput } from '@mui/material';
import Button from '@mui/material/Button';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { EzCenterContent } from '../../components/core/ez-center-content/EzCenterContent';
import { loginAction } from '../../features/auth/auth.actions';
import { useAppDispatch, useAppSelector } from '../../hooks/redux-toolkit.hook';
import { useToast } from '../../hooks/toaster.hook';
import './login.scss';
import { PulseLoader } from 'react-spinners';
import googleLogo from '../../assets/images/google-logo.svg';

export function LoginView() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const toaster = useToast();
	const { isSuccess, isLoading, user } = useAppSelector((state: any) => state.auth);
	const [ formData, setFormData ] = useState({
		username: '',
		password: ''
	});

	useEffect(
		() => {
			toaster.reset();
			if (isSuccess || user) navigate('/dashboard');
		},
		[ isSuccess, user ]
	);

	const submitLoginForm = async (e: any) => {
		e.preventDefault();
		const result: any = await dispatch(loginAction(formData));
		if (result.error) {
			toaster.error('ERRORS.AUTH');
		} else {
			toaster.success('MESSAGES.AUTH_SUCCESS');
		}
	};

	const onChange = (e: any) => {
		setFormData((prevState) => ({
			...prevState,
			[e.target.name]: e.target.value
		}));
	};

	const [ showPassword, setShowPassword ] = React.useState(false);

	const handleClickShowPassword = () => setShowPassword((show) => !show);

	const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
	};

	return (
		<EzCenterContent>
			<form onSubmit={submitLoginForm} className="login">
				<div className="login__left-section">
					<h1 className="login__title">ezWork</h1>
				</div>
				<div className="login__form-section">
					<h1 className="login__form-title">Bentornato!</h1>
					<h2 className="login__form-subtitle">Effettua l’accesso per cominciare ad usare ezWork</h2>
					<br />
					<div className="login__fields">
						<OutlinedInput
							name="username"
							placeholder="Username"
							onChange={onChange}
							value={formData.username}
							startAdornment={
								<InputAdornment position="start">
									<AccountCircleOutlined />
								</InputAdornment>
							}
						/>
						<OutlinedInput
							name="password"
							placeholder="password"
							onChange={onChange}
							type={showPassword ? 'text' : 'password'}
							value={formData.password}
							startAdornment={
								<InputAdornment position="start">
									<LockOutlined />
								</InputAdornment>
							}
							endAdornment={
								<InputAdornment position="end">
									<IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
										{showPassword ? <VisibilityOff /> : <Visibility />}
									</IconButton>
								</InputAdornment>
							}
						/>

						{/* <EzInput icon={<div className="material-symbols-outlined">account_circle</div>} name="username" type="text" placeholder="Username" onChange={onChange} value={formData.username} /> */}
						{/* <EzInput name="password" type="password" placeholder="Password" onChange={onChange} value={formData.password} /> */}
						<div className="login__forgot-password">
							<a className="cursor-pointer" onClick={() => alert('Funzionalità non disponibile. Contattare un amministratore di sistema')}>
								Password dimenticata?
							</a>
						</div>
						<Button variant="contained" color={isLoading ? 'secondary' : 'primary'} type="submit" size="large">
							{!isLoading ? (
								t('COMMON.LOGIN_BTN')
							) : (
								<div>
									<PulseLoader size={5} color="#fff" className="login__spinner !font-semibold" />
								</div>
							)}
						</Button>
						<Button
							startIcon={
								<div className="absolute top-0 left-2 h-full flex items-center">
									<img src={googleLogo} alt="Google logo" width={40} />
								</div>
							}
							variant="outlined"
							type="button"
							color="tprimary"
							className="font-semibold"
							size="large"
							onClick={() => alert('Funzionalità non disponibile. Contattare un amministratore di sistema')}
						>
							Accedi con Google
						</Button>
					</div>
				</div>
			</form>

			{/* <div className="card flex-shrink-0 w-full max-w-xl shadow-xl bg-base-100">
				<form onSubmit={submitLoginForm} className="card-body gap-3">
					<h1 className="text-primary text-center text-4xl font-bold mb-5">{t('PAGES.LOGIN_TITLE')}</h1>
					<div className="input-group">
						<span>
							<FaUser />
						</span>
						<input onChange={onChange} name="username" type="text" placeholder="email" className="w-full input input-bordered" />
					</div>
					<div className="input-group">
						<span>
							<FaKey />
						</span>
						<input onChange={onChange} name="password" type="password" placeholder="password" className="w-full input input-bordered" />
					</div>
					<Link to="/" className="label-text-alt link link-hover">
						{t('COMMON.FORGOTTEN_PASSWORD')}
					</Link>
					<button type="submit" className={'btn btn-primary ' + (isLoading ? 'loading' : '')}>
						{!isLoading ? t('COMMON.LOGIN_BTN') : ''}
					</button>
					<Link to="/register" className="btn btn-outline btn-primary">
						{t('COMMON.REGISTER_BTN')}
					</Link>
				</form>
			</div> */}
		</EzCenterContent>
	);
}
