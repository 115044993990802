import {createSlice} from '@reduxjs/toolkit';
import {TargetState} from '../../om/features/target.state';
import {createTargetBuilders, getAllBuilders} from './targets.builders';

const initialState: TargetState = {
  targets: [],
  targetDetails: null,
  isTargetsLoading: false,
  isTargetDetailsLoading: false,
  isCreateTargetSuccess: false,
};

export const targetsSlice = createSlice({
  name: 'targets',
  initialState,
  reducers: {
    reset: (state: TargetState) => {
      state = initialState;
    },
  },
  extraReducers: (builder) => {
    getAllBuilders(builder);
    createTargetBuilders(builder);
  },
});

export default targetsSlice.reducer;
