import { createSlice } from '@reduxjs/toolkit';
import { ClientsState } from '../../om/features/clients.state';
import { createClientBuilders, getAllBuilders } from './clients.builders';

const initialState: ClientsState = {
	clients: [],
	isClientsLoading: false,
	isAddClientLoading: false,
	isAddClientError: false,
	isAddClientSuccess: false,
	isDeleteClientError: false,
	isDeleteClientSuccess: false
};

export const clientsSlice = createSlice({
	name: 'clients',
	initialState,
	reducers: {
		reset: (state: ClientsState) => {
			state = initialState;
		}
	},
	extraReducers: (builder) => {
		getAllBuilders(builder);
		createClientBuilders(builder);
	}
});

export default clientsSlice.reducer;
