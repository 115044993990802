import { Button, Menu, MenuItem, TableCell } from '@mui/material';
import { useState } from 'react';
import { IoEllipsisHorizontal } from 'react-icons/io5';
import { usePopper } from 'react-popper';
import { EzTableAction } from '../../../../om/ez-table-action';

export function EzTableDataActions({ actions, item }: any) {
	const [ anchorageElement, setAnchorageElement ]: any = useState(null);
	const [ contentElement, setContentElement ]: any = useState(null);
	const [ anchorEl, setAnchorEl ] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	const handleClick = (event: any) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const onActionClick = (callback: Function) => {
		callback();
		setAnchorEl(null);
	};

	const getFlipSettings = () => {
		return {
			name: 'flip',
			options: {
				fallbackPlacements: [ 'top', 'bottom' ]
			}
		};
	};

	const popper = usePopper(anchorageElement, contentElement, {
		placement: 'bottom',
		modifiers: [ getFlipSettings() ]
	});
	const { styles, attributes } = popper;

	if (actions)
		return (
			<TableCell>
				<div className="dropdown dropdown-bottom flex justify-end items-center max-h-8">
					<Button id="basic-button" aria-controls={open ? 'basic-menu' : undefined} aria-haspopup="true" aria-expanded={open ? 'true' : undefined} onClick={handleClick}>
						<IoEllipsisHorizontal size={20} />
					</Button>
					<Menu
						id="basic-menu"
						sx={{ borderRadius: '1rem' }}
						anchorEl={anchorEl}
						open={open}
						onClose={handleClose}
						MenuListProps={{
							'aria-labelledby': 'basic-button'
						}}
					>
						{actions.map((action: EzTableAction) => <MenuItem onClick={() => onActionClick(() => action.onClick(item))}>{action.desription}</MenuItem>)}
					</Menu>
				</div>
			</TableCell>
		);
	return null;
}
