import {TargetState} from '../../om/features/target.state';
import {getAllTargetsAction, createTargetAction, getOneTargetAction} from './targets.actions';

export const getAllBuilders = (builder: any): any => {
  builder
    .addCase(getAllTargetsAction.pending, (state: TargetState) => {
      state.isTargetsLoading = true;
    })
    .addCase(getAllTargetsAction.rejected, (state: TargetState, action: any) => {
      state.isTargetsLoading = false;
    })
    .addCase(getAllTargetsAction.fulfilled, (state: TargetState, action: any) => {
      state.isTargetsLoading = false;
      state.targets = action.payload;
    });
};

export const createTargetBuilders = (builder: any): any =>
  builder
    .addCase(createTargetAction.pending, (state: TargetState) => {
      state.isCreateTargetSuccess = false;
    })
    .addCase(createTargetAction.rejected, (state: TargetState, action: any) => {
      state.isCreateTargetSuccess = false;
    })
    .addCase(createTargetAction.fulfilled, (state: TargetState, action: any) => {
      state.isCreateTargetSuccess = true;
    });

export const getOneTargetBuilders = (builder: any): any =>
  builder
    .addCase(getOneTargetAction.pending, (state: TargetState) => {
      state.isTargetDetailsLoading = true;
      state.targetDetails = null;
    })
    .addCase(getOneTargetAction.rejected, (state: TargetState, action: any) => {
      state.isTargetDetailsLoading = false;
      state.targetDetails = null;
    })
    .addCase(getOneTargetAction.fulfilled, (state: TargetState, action: any) => {
      state.isTargetDetailsLoading = false;
      state.targetDetails = action.payload;
    });
