import { apiManagerUtil } from '../../utils/api-manager.util';

const apiManager = apiManagerUtil();

const login = async (userData: any) => {
	const response = await apiManager.post('auth/login', userData);
	return response.data;
};

const logout = async () => {
	const response = await apiManager.put('auth/logout', null);
	return response.data;
};

const getProfile = async () => {
	const response = await apiManager.get('profile');
	return response.data;
};

const authService = { login, logout, getProfile };
export default authService;
