import crudActions from '../common/crud.actions.abs';
import targetsService from './targets.service';

const {getAllAction, createAction, updateAction, searchAction, getOneAction, deleteAction} = crudActions(
  'targets',
  targetsService
);

export {
  createAction as createTargetAction,
  deleteAction as deleteTargetAction,
  getAllAction as getAllTargetsAction,
  getOneAction as getOneTargetAction,
  searchAction as searchTargetAction,
  updateAction as updateTargetAction,
};
