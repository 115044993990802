import { Autocomplete, OutlinedInput, TextField } from '@mui/material';
import { OrderDTO } from '../../om/dto/orders/order-dto';
import { tableUtil } from '../../utils/table.util';
import { ClientDTO } from '../../om/dto/clients/client-dto';

export function OrdersListViewFiltersManager() {
	const { doFilterSearch, filterField } = tableUtil();

	const getOrderPanelFilters = (
    filtersToAdd: any,
    clients: ClientDTO[],
    onFilterChange: (e: any) => void,
    onClientFilterChange: (e: any) => void
  ) => [
    {
      label: 'ID',
      input: (
        <OutlinedInput
          name='id'
          placeholder=''
          className='w-full'
          size='small'
          onChange={onFilterChange}
          value={filtersToAdd?.id}
        />
      ),
    },
    {
      label: 'Cliente',
      input: (
        <Autocomplete
          options={clients.map((client: ClientDTO) => ({
            label: client.company,
            code: client.code,
          }))}
          className='w-full'
          size='small'
          onChange={onClientFilterChange}
          value={filtersToAdd?.clientDescription}
          renderInput={(params) => (
            <TextField {...params} label='' name='clientDescription' />
          )}
        />
      ),
    },
    {
      label: 'Importo',
      input: (
        <OutlinedInput
          name='amount'
          placeholder=''
          className='w-full'
          size='small'
          onChange={onFilterChange}
          value={filtersToAdd?.amount}
        />
      ),
    },
  ];

	const filterOrdersFromResearch = (
		ordersFiltersList: any,
		order: OrderDTO
	): boolean =>
		ordersFiltersList?.search && '' !== ordersFiltersList?.search
			? doFilterSearch(order, ordersFiltersList)
			: true;

	const filterStringField = (
		ordersFiltersList: any,
		order: OrderDTO,
		key: string
	): boolean =>
		ordersFiltersList && ordersFiltersList[key]
			? filterField(order, ordersFiltersList, key)
			: true;

	const filterOrderSingleField = (
		ordersFiltersList: any,
		order: OrderDTO
	): boolean =>
		filterStringField(ordersFiltersList, order, 'id') &&
		filterStringField(ordersFiltersList, order, 'amount') &&
		filterStringField(ordersFiltersList, order, 'clientDescription');

	return {
		getOrderPanelFilters,
		filterOrdersFromResearch,
		filterOrderSingleField
	};
}
