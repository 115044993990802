import { createSlice } from '@reduxjs/toolkit';
import { OrderState } from '../../om/features/order.state';
import { createOrderBuilders, getAllBuilders, getCountersBuilders } from './orders.builders';

const initialState: OrderState = {
	orders: [],
	orderDetails: null,
	isOrdersLoading: false,
	isOrderDetailsLoading: false,
	counters: null,
	isGetCountersLoading: false,
	isCreateOrderSuccess: false
};

export const ordersSlice = createSlice({
	name: 'orders',
	initialState,
	reducers: {
		reset: (state: OrderState) => {
			state = initialState;
		}
	},
	extraReducers: (builder) => {
		getAllBuilders(builder);
		getCountersBuilders(builder);
		createOrderBuilders(builder);
	}
});

export default ordersSlice.reducer;
